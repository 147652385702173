import React from "react";

const PrivacyPolicyPage = () => {
  return (
    <div className="max-w-screen-xl mx-auto p-6">
      <div
        name="termly-embed"
        data-id="c7850f3c-da53-4490-9267-afa12944eb7d"
      ></div>
      <script type="text/javascript">
        {(function (d, s, id) {
          var js,
            tjs = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) return;
          js = d.createElement(s);
          js.id = id;
          js.src = "https://app.termly.io/embed-policy.min.js";
          tjs.parentNode.insertBefore(js, tjs);
        })(document, "script", "termly-jssdk")}
      </script>
    </div>
  );
};

export default PrivacyPolicyPage;
