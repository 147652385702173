const VyroSvg = (props) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_69_27)">
      <path
        d="M56 27C56 42.1242 44.2401 57.0957 28.5 57.0957C12.7599 57.0957 0 42.6046 0 27.4804C0 12.3563 12.7599 -0.5 28.5 -0.5C44.2401 -0.5 56 11.8758 56 27Z"
        fill="#DD2A7B"
      />
      <path
        d="M9.69279 33.0678L9.10991 33.4871L8 34.5784H9.80112H16.6018L18.5442 34.4792H20.209L21.8739 34.5784L23.4463 34.876L24.9262 35.2729L25.5736 35.8681L26.2717 36.7162V37.4555L25.9436 38.15L25.3991 39.4525L24.9628 40.3646L24.6487 41.4239L23.3846 43.8445L21.7814 45.69L19.2911 47.4758L16.6018 49.3608L14.752 51.1466L12.9946 53.8253L15.7694 55.115L19.2911 56.2063L22.7814 56.7824L25.2036 57L25.6661 55.4126L27.8859 52.0395L29.7358 50.1545L31.0307 48.7655L32.8805 47.0789L35.0079 44.6979L36.4877 41.92L37.1352 40.1342L37.6151 36.7162L53.0549 43.2613L38.2198 32.8198L58 26.6415L37.1352 28.5073L56.3517 14.1351L35.0079 24.8557L49.2088 5.34234L33.2747 21.2841L38.7692 -1.8018L30.5275 18.5315L26.2211 -4L27 17.5L26.2211 17.4149L25.3977 17.3157H24.5562H24H23.0763L21.3189 18.0102L19.6541 19.6968L18.5442 21.2841L17.9892 23.0699L17.8042 24.8557L18.1742 26.6415L18.7292 28.1297L17.5459 28.5073L15.8008 29.4194L14.0557 30.3315L12.3105 31.2436L10.7748 32.1973L9.69279 33.0678Z"
        fill="white"
        stroke="#DD2A7B"
      />
      <path
        d="M28 26.5C28 27.3284 27.5 28 26.5 28C25.6716 28 25 27.5 25 26.5C25 25.6716 25.6716 25 26.5 25C27.3284 25 28 25.6716 28 26.5Z"
        fill="#DD2A7B"
      />
      <path
        d="M27 26.5C27 26.7761 26.7761 27 26.5 27C26.2239 27 26 26.7761 26 26.5C26 26.2239 26.2239 26 26.5 26C26.7761 26 27 26.2239 27 26.5Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_69_27">
        <rect width="56" height="56" rx="28" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default VyroSvg;

/*<svg aria-hidden="true" viewBox="0 0 24 24" {...props}>
      <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z" />
    </svg>;*/
