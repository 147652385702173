import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import SidebarMenu from "./SidebarMenu";
import BottomBar from "./BottomBar";

const Sidebar = () => {
  const queryClient = useQueryClient();
  const { mutate: logout } = useMutation({
    mutationFn: async () => {
      const res = await fetch("/api/auth/logout", {
        method: "POST",
      });

      if (!res.ok) {
        const errorData = await res.json(); // Parse error details if any
        throw new Error(
          errorData.error || "Something went wrong during logout"
        );
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["authUser"] }); // Clear cached auth user
      navigate("/login"); // Redirect to login page after successful logout
    },
    onError: (error) => {
      console.error("Logout failed:", error.message);
      toast.error(error.message || "Logout failed");
    },
  });

  const { data: authUser } = useQuery({ queryKey: ["authUser"] });

  return (
    <div className="flex flex-col md:flex-row">
      <SidebarMenu authUser={authUser} />
      <BottomBar authUser={authUser} />
    </div>
  );
};

export default Sidebar;
