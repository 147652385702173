import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import HomePage from "./pages/home/HomePage";
import LoginPage from "./pages/auth/login/LoginPage";
import SignUpPage from "./pages/auth/signup/SignUpPage";
import SplashScreen from "./components/common/SplashScreen";
import Sidebar from "./components/common/Sidebar";
import RightPanel from "./components/common/RightPanel";
import NotificationPage from "./pages/notification/NotificationPage";
import ProfilePage from "./pages/profile/ProfilePage";
import SearchPage from "./pages/search/SearchPage";
import PostPage from "./pages/post/PostPage";
import VyrosPage from "./pages/vyros/VyrosPage";
import CreatePost from "./pages/home/CreatePost";
import PrivacyPolicyPage from "./components/common/PrivacyPolicyPage";
import TermsOfServicePage from "./components/common/TermsOfServicePage";
import TopLikedUsersPage from "./components/common/TopLikedUsersPage";
import { Toaster } from "react-hot-toast";
import "./styles/globals.css";
import PersistentLayout from "./components/common/PersistentLayout";
import AccountManagementPage from "./pages/AccountManagementPage";

function App() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [showInstallBanner, setShowInstallBanner] = useState(false);
  const [showIosPrompt, setShowIosPrompt] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [appInstalled, setAppInstalled] = useState(false); // Track if app is installed

  const { data: authUser, isLoading } = useQuery({
    queryKey: ["authUser"],
    queryFn: async () => {
      const res = await fetch("/api/auth/me", {
        method: "GET",
        credentials: "include",
      });
      const data = await res.json();
      if (data.error) return null;
      if (!res.ok) {
        throw new Error(data.error || "Something went wrong");
      }
      return data;
    },
    retry: false,
  });

  // Detect iOS and show custom prompt
  useEffect(() => {
    const isIos =
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    const isInStandaloneMode = window.matchMedia(
      "(display-mode: standalone)"
    ).matches;

    if (isIos && !isInStandaloneMode) {
      setTimeout(() => {
        setShowIosPrompt(true);
      }, 30000); // Show prompt after 30 seconds
    }
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const hasOAuthCode = searchParams.get("code");

    if (hasOAuthCode) {
      queryClient.invalidateQueries(["authUser"]);
      navigate("/");
    }
  }, [queryClient, navigate]);

  useEffect(() => {
    if ("serviceWorker" in navigator) {
      window.addEventListener("load", () => {
        navigator.serviceWorker
          .register("/serviceWorker.js")
          .then((registration) => {
            console.log("ServiceWorker registered: ", registration);
          })
          .catch((registrationError) => {
            console.log(
              "ServiceWorker registration failed: ",
              registrationError
            );
          });
      });
    }
  }, []);

  // Standard PWA install prompt for Android and desktop users
  useEffect(() => {
    const isStandalone = window.matchMedia(
      "(display-mode: standalone)"
    ).matches;
    const urlParams = new URLSearchParams(window.location.search);
    const isPWALaunch = urlParams.get("source") === "pwa";

    if (isStandalone || isPWALaunch) {
      return;
    }

    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
      setTimeout(() => {
        setShowInstallBanner(true); // Show the banner after 30 seconds
      }, 30000);
    };

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    // Listen for app installation
    window.addEventListener("appinstalled", () => {
      setAppInstalled(true); // Track installation status
      setShowInstallBanner(false);
      setDeferredPrompt(null);

      // Redirect the user to the app after a short delay
      setTimeout(() => {
        alert(
          "App installed successfully! Please open it from your home screen."
        );
      }, 1000); // Display message after 1 second
    });

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
      window.removeEventListener("appinstalled", () => {
        setShowInstallBanner(false);
        setDeferredPrompt(null);
      });
    };
  }, []);

  const handleInstallClick = async () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      const { outcome } = await deferredPrompt.userChoice;
      if (outcome === "accepted") {
        console.log("User accepted the install prompt");
      } else {
        console.log("User dismissed the install prompt");
      }
      setShowInstallBanner(false);
      setDeferredPrompt(null);
    }
  };

  const handleIosPromptClose = () => {
    setShowIosPrompt(false);
  };

  if (isLoading) {
    return <SplashScreen />;
  }

  return (
    <>
      <Routes>
        {/* Authenticated routes wrapped in PersistentLayout with pageType set to 'home' */}
        <Route element={<PersistentLayout authUser={authUser} />}>
          <Route
            path="/"
            element={authUser ? <HomePage /> : <Navigate to="/login" />}
          />
          <Route
            path="/notifications"
            element={authUser ? <NotificationPage /> : <Navigate to="/login" />}
          />
          <Route
            path="/profile/:username"
            element={authUser ? <ProfilePage /> : <Navigate to="/login" />}
          />
          <Route
            path="/search"
            element={authUser ? <SearchPage /> : <Navigate to="/login" />}
          />
          <Route
            path="/post/:postId"
            element={authUser ? <PostPage /> : <Navigate to="/login" />}
          />
          <Route
            path="/create-post"
            element={authUser ? <CreatePost /> : <Navigate to="/login" />}
          />
          <Route
            path="/top-liked-users"
            element={
              authUser ? <TopLikedUsersPage /> : <Navigate to="/login" />
            }
          />
        </Route>

        {/* Authenticated routes wrapped in PersistentLayout with pageType set to 'vyros' */}
        <Route
          element={<PersistentLayout authUser={authUser} pageType="vyros" />}
        >
          <Route
            path="/vyros"
            element={authUser ? <VyrosPage /> : <Navigate to="/login" />}
          />
        </Route>

        {/* Unauthenticated routes */}
        <Route
          path="/login"
          element={!authUser ? <LoginPage /> : <Navigate to="/" />}
        />
        <Route
          path="/signup"
          element={!authUser ? <SignUpPage /> : <Navigate to="/" />}
        />
        <Route path="/terms-of-service" element={<TermsOfServicePage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route
          path="/account-management"
          element={
            authUser ? <AccountManagementPage /> : <Navigate to="/login" />
          }
        />
      </Routes>

      <Toaster />

      {showInstallBanner && !appInstalled && (
        <div className="install-banner-top">
          <img
            src="/outputDir/android-chrome-192x192.png"
            alt="App Logo"
            className="install-logo"
          />
          <div className="install-text">
            <span className="install-title">256 Vyro</span>
            <span className="install-subtitle">Get app for quick access</span>
          </div>
          <button onClick={handleInstallClick} className="install-button">
            Install
          </button>
        </div>
      )}

      {showIosPrompt && (
        <div className="ios-install-prompt">
          <p>
            To install 256 Vyro, tap <strong>Share</strong> and then{" "}
            <strong>Add to Home Screen</strong>.
          </p>
          <button onClick={handleIosPromptClose} className="ios-close-button">
            Close
          </button>
        </div>
      )}
    </>
  );
}

export default App;
