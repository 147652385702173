import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import LoadingSpinner from "../../components/common/LoadingSpinner";
import Post from "../../components/common/Post";
import PostCommentSection from "../../components/common/PostCommentSection";
import { IoArrowBack } from "react-icons/io5";

const PostPage = () => {
  const { postId } = useParams();
  const navigate = useNavigate();

  const {
    data: post,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["post", postId],
    queryFn: async () => {
      const res = await fetch(`/api/posts/${postId}`);
      const data = await res.json();
      if (!res.ok) throw new Error(data.error || "Something went wrong");
      return data;
    },
    // Deduplication logic if you fetch multiple posts for the feed
    select: (data) => {
      const uniquePosts = new Map(); // Create a Map to store unique posts by ID
      uniquePosts.set(data._id, data); // Store the current post using its ID as the key
      return Array.from(uniquePosts.values())[0]; // Convert Map back to an array and return the first element
    },
  });
  const incrementPostView = async () => {
    try {
      await fetch(`/api/posts/${postId}/views`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });
    } catch (error) {
      console.error("Failed to increment views:", error);
    }
  };

  useEffect(() => {
    if (post) {
      incrementPostView();
    }
  }, [post]);

  if (isLoading) {
    return (
      <div className="flex justify-center h-full items-center">
        <LoadingSpinner size="lg" />
      </div>
    );
  }

  if (isError) {
    return <div className="text-center p-4">Error loading post</div>;
  }

  return (
    <div className="flex-[4_4_0] mr-auto border-r border-gray-700 flex-col min-h-screen bg-black text-white">
      <div className="flex items-center w-full px-4 py-2 border-b border-gray-700">
        <IoArrowBack
          className="text-2xl cursor-pointer"
          onClick={() => navigate(-1)}
        />
        <h1 className="flex-1 text-center font-bold">Post</h1>
      </div>
      <div className="flex-1 overflow-y-auto">
        {post && <Post post={post} />}
        {post && <PostCommentSection post={post} />}
      </div>
    </div>
  );
};

export default PostPage;
