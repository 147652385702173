import {
  FaRegHeart,
  FaHeart,
  FaPlay,
  FaPause,
  FaEllipsisH,
} from "react-icons/fa";
import { useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import LoadingSpinner from "./LoadingSpinner";
import CommentSection from "./CommentSection";
import ShareModal from "./ShareModal";
import { formatPostDate } from "../../utils/date";
import { HiChatBubbleOvalLeftEllipsis } from "react-icons/hi2";
import { RiShareForwardFill } from "react-icons/ri";
import debounce from "lodash/debounce";

const Post = ({ post }) => {
  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
  const [commentCount, setCommentCount] = useState(post.comments.length);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [aspectRatio, setAspectRatio] = useState("landscape"); // Default to landscape
  const [shareCount, setShareCount] = useState(post.shares || 0);
  const { data: authUser } = useQuery({ queryKey: ["authUser"] });
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const videoRef = useRef();

  const [localLikes, setLocalLikes] = useState(post.likes.length);
  const [isLikedLocal, setIsLikedLocal] = useState(
    authUser ? post.likes.includes(authUser._id) : false
  );

  const [isOptionsModalOpen, setIsOptionsModalOpen] = useState(false);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [isTextExpanded, setIsTextExpanded] = useState(false); // State for "See more"

  const isMyPost = authUser ? authUser._id === post.user._id : false;
  const formattedDate = formatPostDate(post.createdAt);

  const maxTextLength = 130; // Maximum length before showing "See more"

  const { mutate: deletePost, isLoading: isDeleting } = useMutation({
    mutationFn: async () => {
      const res = await fetch(`/api/posts/${post._id}`, {
        method: "DELETE",
      });
      const data = await res.json();

      if (!res.ok) {
        throw new Error(data.error || "Something went wrong");
      }
      return data;
    },
    onSuccess: () => {
      toast.success("Post deleted successfully");
      queryClient.invalidateQueries({ queryKey: ["posts"] });
    },
  });

  const { mutate: likePost, isLoading: isLiking } = useMutation({
    mutationFn: async () => {
      if (!authUser) throw new Error("User not authenticated");
      const res = await fetch(`/api/posts/like/${post._id}`, {
        method: "POST",
      });
      const data = await res.json();
      if (!res.ok) {
        throw new Error(data.error || "Something went wrong");
      }
      return data;
    },
    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey: ["posts"] });
      const previousPosts = queryClient.getQueryData({ queryKey: ["posts"] });

      queryClient.setQueryData(["posts"], (oldData) => {
        if (!oldData || !oldData.pages) return oldData;

        return {
          ...oldData,
          pages: oldData.pages.map((page) => ({
            ...page,
            posts: page.posts.map((p) => {
              if (p._id === post._id) {
                const isLiked = p.likes.includes(authUser._id);
                const updatedLikes = isLiked
                  ? p.likes.filter((id) => id !== authUser._id)
                  : [...p.likes, authUser._id];
                return { ...p, likes: updatedLikes };
              }
              return p;
            }),
          })),
        };
      });

      setIsLikedLocal((prev) => !prev);
      setLocalLikes((prev) => (isLikedLocal ? prev - 1 : prev + 1));

      return { previousPosts };
    },
    onError: (err, variables, context) => {
      queryClient.setQueryData(["posts"], context.previousPosts);
      toast.error("Something went wrong");
      setIsLikedLocal((prev) => !prev);
      setLocalLikes((prev) => (isLikedLocal ? prev + 1 : prev - 1));
    },
    // Remove onSettled to avoid full refetch
  });

  const handleDeletePost = () => {
    deletePost();
    setIsDeleteConfirmationOpen(false);
  };

  const handleLikePost = debounce((e) => {
    e.stopPropagation();
    if (!isLiking) {
      likePost();
    }
  }, 300); // Adjust debounce time as necessary

  const handleShare = async (platform) => {
    try {
      const res = await fetch(`/api/posts/${post._id}/shares`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (res.ok) {
        setShareCount((prev) => prev + 1);
        toast.success(`Post shared to ${platform}!`);
      } else {
        throw new Error("Failed to share");
      }
    } catch (error) {
      toast.error("Could not share the post.");
    }
  };

  const handlePostClick = () => {
    if (post.postType === "vyro") {
      navigate(`/vyros?initialVyro=${post._id}`);
    } else {
      navigate(`/post/${post._id}`);
    }
  };

  const handlePlayPauseClick = (e) => {
    e.stopPropagation();
    if (post.postType === "vyro") {
      navigate(`/vyros`, { state: { initialVyro: post._id } });
    } else if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play();
        setIsPlaying(true);
      } else {
        videoRef.current.pause();
        setIsPlaying(false);
      }
    }
  };

  const handleToggleTextExpansion = (e) => {
    e.stopPropagation();
    setIsTextExpanded((prev) => !prev);
  };

  const handleVideoLoad = () => {
    setIsVideoLoaded(true);
    const videoWidth = videoRef.current.videoWidth;
    const videoHeight = videoRef.current.videoHeight;
    if (videoHeight > videoWidth) {
      setAspectRatio("portrait");
    } else {
      setAspectRatio("landscape");
    }
  };

  const handleCommentAdded = () => {
    setCommentCount((prevCount) => prevCount + 1);
  };

  const handleOutsideClick = (e) => {
    e.stopPropagation();
    setIsOptionsModalOpen(false);
  };

  if (!authUser) return null;

  return (
    <>
      <div
        className="flex gap-2 items-start p-4 border-b border-gray-700"
        onClick={handlePostClick}
      >
        <div className="avatar">
          <Link
            to={`/profile/${post.user.username}`}
            className="w-8 h-8 rounded-full overflow-hidden flex items-center justify-center"
            onClick={(e) => e.stopPropagation()}
          >
            <img
              src={post.user.profileImg || "/avatar-placeholder.png"}
              alt={`${post.user.username}'s avatar`}
              className="w-full h-full object-cover"
            />
          </Link>
        </div>
        <div className="flex flex-col flex-1">
          <div className="flex gap-2 items-center">
            <Link
              to={`/profile/${post.user.username}`}
              className="font-bold"
              onClick={(e) => e.stopPropagation()}
            >
              {post.user.fullName}
            </Link>
            <span className="text-gray-700 flex gap-1 text-sm">
              <span>·</span>
              <span>{formattedDate}</span>
            </span>

            {isMyPost && (
              <div className="relative ml-auto">
                <FaEllipsisH
                  className="cursor-pointer text-gray-500 hover:text-gray-900"
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsOptionsModalOpen(true);
                  }}
                />
                {isOptionsModalOpen && (
                  <div
                    className="absolute top-8 right-0 bg-white shadow-lg rounded-lg p-4 z-50"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <button
                      className="text-red-600 hover:text-red-800"
                      onClick={() => {
                        setIsOptionsModalOpen(false);
                        setIsDeleteConfirmationOpen(true);
                      }}
                    >
                      Delete Post
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>

          <div className="flex flex-col gap-3 overflow-hidden">
            <span>
              {isTextExpanded || post.text.length <= maxTextLength
                ? post.text
                : `${post.text.substring(0, maxTextLength)}`}
            </span>
            {post.text.length > maxTextLength && (
              <span
                onClick={handleToggleTextExpansion}
                style={{
                  color: "grey",
                  cursor: "pointer",
                  display: "block",
                }}
              >
                {isTextExpanded ? "See less" : "... See more"}
              </span>
            )}
            {post.media && post.postType === "image" && (
              <img
                src={post.media}
                className="object-contain rounded-lg"
                alt=""
              />
            )}

            {/* Video Handling */}
            {post.media && post.postType === "vyro" && (
              <div
                className={`relative ${isVideoLoaded ? "" : "placeholder"} ${
                  aspectRatio === "portrait"
                    ? "portrait-video"
                    : "landscape-video"
                }`}
              >
                {!isVideoLoaded && (
                  <div className="loading-overlay">
                    <LoadingSpinner />
                  </div>
                )}
                <video
                  src={post.media}
                  ref={videoRef}
                  onLoadedData={handleVideoLoad}
                  onError={() => toast.error("Video failed to load")}
                  className="object-contain rounded-lg w-full h-full"
                  muted
                  playsInline
                  preload="auto"
                />
                {!isPlaying && isVideoLoaded && (
                  <button
                    className="play-button"
                    onClick={handlePlayPauseClick}
                  >
                    <div className="play-button-inner">
                      <FaPlay className="w-6 h-6" />
                    </div>
                  </button>
                )}
                {isPlaying && isVideoLoaded && (
                  <button
                    className="mute-button absolute bottom-4 right-4"
                    onClick={handlePlayPauseClick}
                  >
                    <FaPause />
                  </button>
                )}
              </div>
            )}
          </div>

          <div className="flex justify-start mt-3">
            <div className="flex gap-4 items-center justify-between">
              <div
                className="flex gap-1 items-center group cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation(); // Stop the click event from propagating
                  handleLikePost(e);
                }}
              >
                {isLiking && <LoadingSpinner size="sm" />}
                {!isLikedLocal && !isLiking && (
                  <FaRegHeart className="w-4 h-4 cursor-pointer text-slate-500 group-hover:text-pink-500" />
                )}
                {isLikedLocal && !isLiking && (
                  <FaHeart className="w-4 h-4 cursor-pointer text-pink-500" />
                )}
                <span
                  className={`text-sm group-hover:text-pink-500 ${
                    isLikedLocal ? "text-pink-500" : "text-slate-500"
                  }`}
                >
                  {localLikes}
                </span>
              </div>
              <div
                className="flex gap-1 items-center cursor-pointer group"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsCommentModalOpen(true);
                }}
              >
                <HiChatBubbleOvalLeftEllipsis className="w-6 h-6 text-slate-500 group-hover:text-pink-500" />
                <span className="text-sm text-slate-500 group-hover:text-pink-500">
                  {commentCount}
                </span>
              </div>

              {/* Share Button with share count */}
              <div
                className="flex gap-1 items-center group cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsShareModalOpen(true);
                }}
              >
                <RiShareForwardFill className="w-6 h-6 text-slate-500 group-hover:text-pink-500" />
                <span className="text-sm text-slate-500 group-hover:text-pink-500">
                  {shareCount}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CommentSection
        post={post}
        isCommentModalOpen={isCommentModalOpen}
        setIsCommentModalOpen={setIsCommentModalOpen}
        queryClient={queryClient}
        onCommentAdded={handleCommentAdded}
      />

      {/* Share Modal */}
      <ShareModal
        isOpen={isShareModalOpen}
        onClose={() => setIsShareModalOpen(false)}
        postId={post._id}
        onShare={handleShare}
      />

      {/* Delete Confirmation Modal */}
      {isDeleteConfirmationOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div
            className="bg-secondary rounded-lg p-6"
            onClick={(e) => e.stopPropagation()}
          >
            <h3 className="text-white text-center mb-4">
              Are you sure you want to delete this post?
            </h3>
            <div className="flex justify-center gap-4">
              <button className="btn btn-danger" onClick={handleDeletePost}>
                Delete
              </button>
              <button
                className="btn btn-secondary"
                onClick={() => setIsDeleteConfirmationOpen(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Clicking outside to close options modal */}
      {isOptionsModalOpen && (
        <div
          className="fixed inset-0 z-40 bg-transparent"
          onClick={handleOutsideClick}
        ></div>
      )}
    </>
  );
};

export default Post;
