import React, { useEffect, useRef, useState } from "react";
import {
  useQuery,
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import LoadingSpinner from "../../components/common/LoadingSpinner";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-hot-toast";
import "../../styles/globals.css";
import { IoNotifications } from "react-icons/io5";
import Vyro from "../../components/common/Vyro";
import VyroSvg from "../../components/svgs/VyroSvg";

const VyrosPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const initialVyroId = searchParams.get("initialVyro");

  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
  const [isMutedGlobal, setIsMutedGlobal] = useState(true);
  const queryClient = useQueryClient();
  const { data: authUser } = useQuery({ queryKey: ["authUser"] });
  const [unreadCount, setUnreadCount] = useState(0);

  const storageKey = `scroll-position-${location.pathname}`;
  const vyrosContainerRef = useRef();
  const videoRefs = useRef([]);
  const observerRef = useRef();
  const startYRef = useRef(0);

  // Notification query
  useQuery({
    queryKey: ["notifications"],
    queryFn: async () => {
      const res = await fetch("/api/notifications");
      return res.json();
    },
    onSuccess: (data) => {
      const unreadNotifications = data.filter((n) => !n.read).length;
      setUnreadCount(unreadNotifications > 99 ? "99+" : unreadNotifications);
    },
  });

  const {
    data: vyrosData,
    isLoading,
    isError,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: ["vyros", { sort: "trending" }],
    queryFn: async ({ pageParam = 1 }) => {
      const res = await fetch(
        `/api/posts/vyros?page=${pageParam}&sort=trending`
      );
      const data = await res.json();
      if (!res.ok) throw new Error(data.error || "Something went wrong");
      return data;
    },
    getNextPageParam: (lastPage, pages) =>
      lastPage.hasNextPage ? pages.length + 1 : undefined,
    select: (data) => {
      const allVyros = data.pages.flatMap((page) => page.vyros);
      const uniqueVyros = Array.from(
        new Map(allVyros.map((vyro) => [vyro._id, vyro])).values()
      );
      return { ...data, pages: [{ vyros: uniqueVyros }] };
    },
  });

  const prioritizedVyros = React.useMemo(() => {
    if (!initialVyroId || !vyrosData) return vyrosData;

    const allVyros = vyrosData.pages.flatMap((page) => page.vyros);
    const selectedVideo = allVyros.find((vyro) => vyro._id === initialVyroId);
    const remainingVideos = allVyros.filter(
      (vyro) => vyro._id !== initialVyroId
    );

    return {
      ...vyrosData,
      pages: [
        {
          vyros: selectedVideo ? [selectedVideo, ...remainingVideos] : allVyros,
        },
      ],
    };
  }, [initialVyroId, vyrosData]);

  // Restore scroll position on data load completion
  useEffect(() => {
    if (vyrosContainerRef.current && !isLoading) {
      const savedPosition =
        parseInt(sessionStorage.getItem(storageKey), 10) || 0;
      vyrosContainerRef.current.scrollTop = savedPosition;
    }
  }, [vyrosData, isLoading]);

  // Save scroll position on scroll event
  const handleScroll = () => {
    if (vyrosContainerRef.current) {
      sessionStorage.setItem(storageKey, vyrosContainerRef.current.scrollTop);
    }
  };

  const lastVyroRef = (node) => {
    if (isLoading) return;
    if (observerRef.current) observerRef.current.disconnect();
    observerRef.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasNextPage) {
        fetchNextPage();
      }
    });
    if (node) observerRef.current.observe(node);
  };

  // Handle video auto-play and swipe navigation
  useEffect(() => {
    const vyrosContainer = vyrosContainerRef.current;
    if (!vyrosContainer) return;

    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        const video = entry.target;
        video[entry.isIntersecting ? "play" : "pause"]();
      });
    };

    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.75,
    });

    videoRefs.current.forEach((video) => {
      if (video) observer.observe(video);
    });

    const handleTouchStart = (event) => {
      if (!isCommentModalOpen) startYRef.current = event.touches[0].clientY;
    };

    const handleTouchEnd = (event) => {
      if (!isCommentModalOpen) {
        const endY = event.changedTouches[0].clientY;
        const deltaY = startYRef.current - endY;
        const swipeThreshold = 50;

        if (Math.abs(deltaY) > swipeThreshold) {
          const direction = deltaY > 0 ? 1 : -1;
          const newIndex = Math.min(
            Math.max(0, videoRefs.current.length - 1),
            direction
          );

          videoRefs.current[newIndex]?.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
      }
    };

    vyrosContainer.addEventListener("touchstart", handleTouchStart);
    vyrosContainer.addEventListener("touchend", handleTouchEnd);

    return () => {
      observer.disconnect();
      vyrosContainer.removeEventListener("touchstart", handleTouchStart);
      vyrosContainer.removeEventListener("touchend", handleTouchEnd);
    };
  }, [vyrosData, isCommentModalOpen]);

  // Handle like action
  const { mutate: likeVyro, isLoading: isLiking } = useMutation({
    mutationFn: async (vyroId) => {
      const res = await fetch(`/api/posts/like/${vyroId}`, { method: "POST" });
      const data = await res.json();
      if (!res.ok) throw new Error(data.error || "Something went wrong");
      return data;
    },
    onMutate: async (vyroId) => {
      await queryClient.cancelQueries({ queryKey: ["vyros"] });
      const previousVyros = queryClient.getQueryData({ queryKey: ["vyros"] });
      queryClient.setQueryData(["vyros"], (oldData) => {
        if (!oldData) return oldData;
        return {
          ...oldData,
          pages: oldData.pages.map((page) => ({
            ...page,
            vyros: page.vyros.map((vyro) => {
              if (vyro._id === vyroId) {
                const isLiked = authUser && vyro.likes.includes(authUser._id);
                const updatedLikes = isLiked
                  ? vyro.likes.filter((id) => id !== authUser._id)
                  : [...vyro.likes, authUser._id];
                return { ...vyro, likes: updatedLikes };
              }
              return vyro;
            }),
          })),
        };
      });
      return { previousVyros };
    },
    onError: (err, variables, context) => {
      queryClient.setQueryData(["vyros"], context.previousVyros);
      toast.error("Something went wrong");
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["vyros"] });
    },
  });

  const handleLikeVyro = (vyroId, e) => {
    e.stopPropagation();
    if (!isLiking) likeVyro(vyroId);
  };

  if (isLoading) {
    return (
      <div className="flex justify-center h-full items-center">
        <LoadingSpinner size="lg" />
      </div>
    );
  }

  if (isError) {
    return <div className="text-center p-4">Error loading vyros</div>;
  }

  return (
    <div
      className={`flex flex-col min-h-screen text-white vyros-container ${
        isCommentModalOpen ? "overflow-hidden" : "overflow-y-auto"
      }`}
      ref={vyrosContainerRef}
      onScroll={handleScroll}
    >
      <div className="fixed top-0 left-0 w-full z-50 flex lg:hidden items-center px-4 py-5 bg-transparent">
        <VyroSvg
          className="w-6 h-6 cursor-pointer lg:hidden"
          onClick={() => navigate(-1)}
        />
        <h1 className="flex-1 text-center font-bold">Vyros</h1>
        <Link to="/notifications" className="relative">
          <IoNotifications className="w-7 h-7 text-white" />
          {unreadCount > 0 && (
            <span className="absolute -top-1 -right-2 bg-red-600 text-white text-xs rounded-full w-5 h-5 flex items-center justify-center">
              {unreadCount}
            </span>
          )}
        </Link>
      </div>

      <div className="flex-1 overflow-y-auto snap-y snap-mandatory">
        {prioritizedVyros.pages.map((page, pageIndex) =>
          page.vyros.map((vyro, vyroIndex) => {
            const isLastVyro =
              pageIndex === prioritizedVyros.pages.length - 1 &&
              vyroIndex === page.vyros.length - 1;
            return (
              <Vyro
                key={vyro._id}
                vyro={vyro}
                index={vyroIndex}
                handleLikeVyro={handleLikeVyro}
                authUser={authUser}
                videoRefs={videoRefs}
                isLastVyro={isLastVyro ? lastVyroRef : null}
                setIsCommentModalOpen={setIsCommentModalOpen}
                initialVyroId={initialVyroId}
                isMutedGlobal={isMutedGlobal}
                setIsMutedGlobal={setIsMutedGlobal}
              />
            );
          })
        )}
        {isFetchingNextPage && (
          <div className="flex justify-center py-4">
            <LoadingSpinner size="lg" />
          </div>
        )}
      </div>
    </div>
  );
};

export default VyrosPage;
