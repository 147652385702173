import { useState, useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { RiSendPlaneFill } from "react-icons/ri";
import { FaRegHeart, FaHeart } from "react-icons/fa";
import LoadingSpinner from "./LoadingSpinner";
import { formatPostDate } from "../../utils/date";

const PostCommentSection = ({ post, onCommentAdded, onCommentDeleted }) => {
  const [comment, setComment] = useState("");
  const [localComments, setLocalComments] = useState(post.comments || []);
  const [longPressedComment, setLongPressedComment] = useState(null); // Track long-pressed comment for deletion
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false); // For delete confirmation modal
  const queryClient = useQueryClient();
  const { data: authUser } = useQuery({ queryKey: ["authUser"] });

  useEffect(() => {
    setLocalComments(post.comments || []);
  }, [post.comments]);

  // Posting a new comment
  const { mutate: commentPost, isPending: isCommenting } = useMutation({
    mutationFn: async () => {
      const res = await fetch(`/api/posts/comment/${post._id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ text: comment }),
      });
      const data = await res.json();
      if (!res.ok) {
        throw new Error(data.error || "Something went wrong");
      }
      return data;
    },
    onSuccess: (newComment) => {
      setLocalComments((prevComments) => [newComment, ...prevComments]);
      setComment("");
      toast.success("Comment posted successfully");
      if (onCommentAdded) onCommentAdded();
    },
    onError: () => {
      toast.error("Something went wrong");
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["posts"] });
    },
  });

  const handlePostComment = (e) => {
    e.preventDefault();
    if (!comment.trim()) {
      toast.error("Comment cannot be empty");
      return;
    }
    commentPost();
  };

  // Like a comment
  const { mutate: likeComment } = useMutation({
    mutationFn: async (commentId) => {
      const res = await fetch(
        `/api/posts/${post._id}/comments/${commentId}/like`,
        {
          method: "POST",
        }
      );
      if (!res.ok) throw new Error("Something went wrong");
      return res.json();
    },
    onSuccess: (data, commentId) => {
      setLocalComments((prev) =>
        prev.map((comment) =>
          comment._id === commentId
            ? { ...comment, likes: data.likes }
            : comment
        )
      );
    },
  });

  const handleLikeComment = (commentId) => {
    if (commentId) {
      likeComment(commentId);
    } else {
      toast.error("Comment ID is missing");
    }
  };

  // Delete a comment
  const { mutate: deleteComment } = useMutation({
    mutationFn: async (commentId) => {
      const res = await fetch(`/api/posts/${post._id}/comments/${commentId}`, {
        method: "DELETE",
      });
      if (!res.ok) throw new Error("Something went wrong");
      return res.json();
    },
    onSuccess: (data, commentId) => {
      toast.success("Comment deleted successfully");
      setLocalComments((prev) =>
        prev.filter((comment) => comment._id !== commentId)
      );
      if (onCommentDeleted) onCommentDeleted(); // Update the comment count
    },
  });

  // Long press to show delete modal
  const handleLongPress = (commentId) => {
    setLongPressedComment(commentId);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteComment = () => {
    if (longPressedComment) {
      deleteComment(longPressedComment);
      setIsDeleteModalOpen(false);
    }
  };

  if (!authUser) return null;

  return (
    <div className="flex flex-col h-full">
      <div className="flex-1 overflow-y-auto p-4">
        <h3 className="text-lg font-semibold text-white mb-2">Comments</h3>
        {localComments.length === 0 && (
          <p className="text-sm text-slate-500">
            No comments, be the first one
          </p>
        )}
        {localComments.map((comment) => (
          <div
            key={comment._id}
            className="flex gap-2 items-start w-full mb-4" // Added margin-bottom to space out the comments
            onContextMenu={(e) => {
              e.preventDefault();
              if (comment.user._id === authUser._id) {
                handleLongPress(comment._id); // Only allow long press for comment owner
              }
            }}
          >
            <div className="avatar">
              <div className="w-8 rounded-full">
                <img
                  src={comment.user.profileImg || "/avatar-placeholder.png"}
                  alt={`${comment.user.username}'s avatar`}
                />
              </div>
            </div>

            <div className="flex-grow">
              <div className="flex flex-col">
                <div className="flex items-center gap-1">
                  <span className="font-normal text-gray-500">
                    {comment.user.fullName}
                  </span>
                </div>
                <div className="text-sm text-white">{comment.text}</div>
                <span className="text-xs mt-2 text-gray-500">
                  {formatPostDate(comment.createdAt)}
                </span>
              </div>
            </div>

            <div className="flex items-center gap-2 ">
              <div
                className="cursor-pointer text-sm text-gray-500"
                onClick={() => handleLikeComment(comment._id)}
              >
                {(comment.likes || []).includes(authUser._id) ? (
                  <FaHeart className="text-pink-500 text-lg" />
                ) : (
                  <FaRegHeart className="text-slate-500 text-lg" />
                )}
                <span className="ml-1 text-sm">
                  {(comment.likes || []).length}
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="p-4 border-t z-50 border-gray-700 fixed bottom-0 w-full bg-secondary">
        <form className="flex gap-2 items-center" onSubmit={handlePostComment}>
          <textarea
            className="textarea w-full p-2 rounded-lg text-sm resize-none focus:outline-none bg-black text-white"
            placeholder="Add comment..."
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
          <button className="btn btn-primary rounded-full btn-sm text-white px-4">
            {isCommenting ? <LoadingSpinner size="md" /> : <RiSendPlaneFill />}
          </button>
        </form>
      </div>

      {/* Delete Confirmation Modal */}
      {isDeleteModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div
            className="bg-secondary rounded-lg p-6"
            onClick={(e) => e.stopPropagation()}
          >
            <h3 className="text-white text-center mb-4">
              Are you sure you want to delete this comment?
            </h3>
            <div className="flex justify-center gap-4">
              <button className="btn btn-danger" onClick={handleDeleteComment}>
                Delete
              </button>
              <button
                className="btn btn-secondary"
                onClick={() => setIsDeleteModalOpen(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PostCommentSection;
