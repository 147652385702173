import { useState } from "react";
import { Link } from "react-router-dom";
import { MdOutlineMail, MdPassword } from "react-icons/md";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import VyroVyrosSvg from "../../../components/svgs/VyroVyrosSvg";
import { FcGoogle } from "react-icons/fc"; // Import Google Icon

const LoginPage = () => {
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });
  const queryClient = useQueryClient();

  const {
    mutateAsync: loginMutation,
    isPending,
    isError,
    error,
  } = useMutation({
    mutationFn: async ({ username, password }) => {
      const res = await fetch("/api/auth/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include", // This ensures cookies (JWT) are included
        body: JSON.stringify({ username, password }),
      });

      if (!res.ok) {
        const errorData = await res.json();
        throw new Error(errorData.error || "Something went wrong");
      }

      return res.json();
    },
    onSuccess: () => {
      // refetch the authUser
      queryClient.invalidateQueries({ queryKey: ["authUser"] });
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await loginMutation(formData);
    } catch (error) {
      console.error("Login failed:", error.message);
    }
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Function to handle Google Login
  const handleGoogleLogin = () => {
    window.location.href = "/api/auth/google"; // Redirect to Google OAuth endpoint
  };

  return (
    <div className="max-w-screen-xl mx-auto flex h-screen">
      <div className="flex-1 flex flex-col justify-center items-center">
        {/* New Section: Purpose of the App */}
        <div className="flex flex-col items-center justify-center ">
          <VyroVyrosSvg className="w-24 items-center lg:hidden fill-white" />
        </div>
        <div className="mt-3 text-center pl-2 pr-2 text-gray-300 max-w-md mx-auto">
          <h2 className="text-3xl font-bold mb-2">Log in to 256 Vyro</h2>
          {/*<p className="text-sm pl-4 pr-4">
            A social media platform where you can share photos, videos, and
            connect with friends. You can follow, like, and engage with posts
            from users all around the world.
          </p>*/}
        </div>

        {/* Google Login Button should be OUTSIDE the form */}
        <div>
          <button
            onClick={handleGoogleLogin}
            className="btn rounded-full btn-outline text-white flex items-center gap-2 mt-4"
          >
            <FcGoogle size={20} />
            <span>Continue with Google</span>
          </button>

          <div className="flex items-center pb-2 pt-2">
            <div className="flex-grow border-t border-pink-500"></div>
            <span className="mx-4 text-pink-500">or</span>
            <div className="flex-grow border-t border-pink-500"></div>
          </div>
        </div>

        <form className="flex gap-4 flex-col" onSubmit={handleSubmit}>
          <label className="input input-bordered rounded flex items-center gap-2">
            <MdOutlineMail />
            <input
              type="text"
              className="grow"
              placeholder="username"
              name="username"
              onChange={handleInputChange}
              value={formData.username}
            />
          </label>

          <label className="input input-bordered rounded flex items-center gap-2">
            <MdPassword />
            <input
              type="password"
              className="grow"
              placeholder="Password"
              name="password"
              onChange={handleInputChange}
              value={formData.password}
            />
          </label>
          <button className="btn rounded-full btn-primary text-white">
            {isPending ? "Loading..." : "Log in"}
          </button>
          {isError && <p className="text-red-500">{error.message}</p>}
        </form>

        <div className="flex flex-col gap-2 mt-4">
          <p className="text-white text-lg">{"Don't"} have an account?</p>
          <Link to="/signup">
            <button className="btn rounded-full btn-primary text-white btn-outline w-full">
              Create new account
            </button>
          </Link>
        </div>

        {/* Privacy Policy */}
        <div className="mt-4 p-4 text-gray-400 max-w-md mx-auto">
          <p className="text-sm p-4 leading-normal text-center">
            By continuing with an account, you agree to our{" "}
            <Link to="/terms-of-service" className="underline text-white">
              Terms of Service
            </Link>{" "}
            and acknowledge that you have read our{" "}
            <Link to="/privacy-policy" className="underline text-white">
              Privacy Policy
            </Link>
            .
          </p>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
