import React from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

const AccountManagementPage = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  // Logout mutation
  const { mutate: logout } = useMutation({
    mutationFn: async () => {
      const res = await fetch("/api/auth/logout", { method: "POST" });
      if (!res.ok) {
        const errorData = await res.json();
        throw new Error(errorData.error || "Logout failed");
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["authUser"] });
      navigate("/login"); // Redirect to login page after logout
    },
    onError: (error) => {
      toast.error(error.message || "Logout failed");
    },
  });

  // Delete account handler
  const handleDeleteAccount = async () => {
    if (
      window.confirm(
        "Are you sure you want to delete your account? This action cannot be undone."
      )
    ) {
      try {
        const res = await fetch("/api/users/delete-account", {
          method: "DELETE",
          credentials: "include",
        });
        if (!res.ok) throw new Error("Failed to delete account");

        toast.success("Your account has been deleted.");
        queryClient.invalidateQueries({ queryKey: ["authUser"] });
        navigate("/login"); // Redirect to login page after account deletion
      } catch (error) {
        toast.error(error.message || "Failed to delete account");
      }
    }
  };

  return (
    <div className="p-4">
      <h2 className="text-lg font-bold mb-4">Account Management</h2>
      <button className="btn btn-primary mb-4 w-full" onClick={() => logout()}>
        Logout
      </button>
      <button className="btn btn-danger w-full" onClick={handleDeleteAccount}>
        Delete Account
      </button>
    </div>
  );
};

export default AccountManagementPage;
