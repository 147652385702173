import { useState, useEffect } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../components/common/LoadingSpinner";
import Post from "../../components/common/Post";
import UserCard from "../../components/common/UserCard";
import { FaSearch } from "react-icons/fa";
import { AiOutlineClose, AiOutlineClockCircle } from "react-icons/ai";
import { BsTrash } from "react-icons/bs";
import { IoArrowBack } from "react-icons/io5";
import { IoClose } from "react-icons/io5";

const SearchPage = () => {
  const [query, setQuery] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [showMore, setShowMore] = useState(false);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { data: authUser } = useQuery({
    queryKey: ["authUser"],
    queryFn: async () => {
      const res = await fetch("/api/auth/me");
      if (!res.ok) {
        throw new Error("Error fetching authenticated user");
      }
      return res.json();
    },
  });

  const { data: recentSearches, refetch: refetchRecentSearches } = useQuery({
    queryKey: ["recentSearches", authUser?._id, showMore],
    queryFn: async () => {
      const res = await fetch(`/api/search/recent?showMore=${showMore}`);
      if (!res.ok) {
        throw new Error("Error fetching recent searches");
      }
      return res.json();
    },
    enabled: !!authUser, // Only fetch recent searches if the user is authenticated
  });

  const { data: searchSuggestions, refetch: refetchSearchSuggestions } =
    useQuery({
      queryKey: ["searchSuggestions", query],
      queryFn: async () => {
        if (query) {
          const res = await fetch(`/api/search?query=${query}`);
          if (!res.ok) {
            throw new Error("Error fetching search suggestions");
          }
          return res.json();
        }
        return { users: [], posts: [] };
      },
      enabled: !!query,
    });

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["searchResults", searchTerm],
    queryFn: async () => {
      if (!searchTerm) return { users: [], posts: [] };
      const res = await fetch(`/api/search?query=${searchTerm}&execute=true`);
      if (!res.ok) {
        throw new Error("Error fetching search results");
      }
      return res.json();
    },
    enabled: !!searchTerm,
  });

  const { mutate: addRecentSearch } = useMutation({
    mutationFn: async (searchQuery) => {
      const res = await fetch(`/api/search/recent`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ query: searchQuery }),
      });
      if (!res.ok) {
        throw new Error("Error saving recent search");
      }
      return res.json();
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["recentSearches", authUser?._id],
      });
    },
  });

  const { mutate: clearRecentSearches } = useMutation({
    mutationFn: async () => {
      const res = await fetch(`/api/search/recent`, {
        method: "DELETE",
      });
      if (!res.ok) {
        throw new Error("Error clearing recent searches");
      }
      return res.json();
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["recentSearches", authUser?._id],
      });
    },
  });

  const { mutate: deleteRecentSearch } = useMutation({
    mutationFn: async (id) => {
      const res = await fetch(`/api/search/recent/${id}`, {
        method: "DELETE",
      });
      if (!res.ok) {
        throw new Error("Error deleting recent search");
      }
      return res.json();
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["recentSearches", authUser?._id],
      });
    },
  });

  const saveToRecentSearches = (searchQuery) => {
    addRecentSearch(searchQuery);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setSearchTerm(query);
    refetch();
    saveToRecentSearches(query);
  };

  const handleUserClick = (username) => {
    saveToRecentSearches(username);
    navigate(`/profile/${username}`);
  };

  const handleRecentSearchClick = (searchQuery) => {
    setQuery(searchQuery);
    setSearchTerm(searchQuery);
    refetch();
  };
  const handlePostClick = (postId) => {
    // Save the post text or some identifier if needed
  };

  useEffect(() => {
    if (authUser) {
      refetchRecentSearches();
    }
  }, [authUser, refetchRecentSearches]);

  return (
    <div className="flex flex-col items-center px-3 pt-5 bg-black min-h-screen text-white">
      <form
        onSubmit={handleSearch}
        className="w-full px-1 max-w-lg flex mb-4 relative"
      >
        <button onClick={() => navigate(-1)} className="mr-2 text-white">
          <IoArrowBack className="text-2xl cursor-pointer" />
        </button>
        <input
          type="text"
          className="w-full px-4 py-2 rounded-l-md focus:outline-none text-white"
          placeholder="Search..."
          value={query}
          onChange={(e) => {
            setQuery(e.target.value);
            if (e.target.value.trim() !== "") {
              refetchSearchSuggestions();
            }
          }}
        />
        <button
          type="submit"
          className="px-4 py-2 bg-primary text-white rounded-r-md"
        >
          <FaSearch />
        </button>
        {query && (
          <AiOutlineClose
            className="absolute right-14 top-3 text-white cursor-pointer"
            onClick={() => setQuery("")}
          />
        )}
      </form>

      {query && searchSuggestions && (
        <div className="w-full max-w-lg  mt-1 rounded-md z-10">
          {searchSuggestions.users.length > 0 && (
            <div className="p-2">
              <h3 className="text-lg font-semibold mb-2 text-white">
                Suggestions
              </h3>
              {searchSuggestions.users.map((user) => (
                <div
                  key={user._id}
                  className="flex items-center mb-2 cursor-pointer hover:bg-gray-700 p-2 rounded-md"
                  onClick={() => handleUserClick(user.username)}
                >
                  <img
                    src={user.profileImg || "/avatar-placeholder.png"}
                    className="w-8 h-8 rounded-full mr-3"
                    alt={`${user.username}'s avatar`}
                  />
                  <div>
                    <p className="font-bold text-white">{user.fullName}</p>
                    <p className="text-sm text-gray-400">@{user.username}</p>
                  </div>
                </div>
              ))}
            </div>
          )}
          {searchSuggestions.posts.length > 0 && (
            <div className="p-2">
              <h3 className="text-lg font-semibold mb-2 text-white">Posts</h3>
              {searchSuggestions.posts.map((post) => (
                <div
                  key={post._id}
                  className="cursor-pointer hover:bg-gray-700 p-2 rounded-md"
                  onClick={() => handlePostClick(post._id)}
                >
                  <Post post={post} />
                </div>
              ))}
            </div>
          )}
        </div>
      )}

      {isLoading && <LoadingSpinner size="lg" />}

      {recentSearches && recentSearches.length > 0 && (
        <div className="w-full max-w-lg mt-4">
          <div className="flex justify-between items-center mb-2">
            <h3 className="text-lg font-semibold text-white">
              Recent Searches
            </h3>
            <button
              className="text-sm text-gray-500 hover:underline"
              onClick={() => clearRecentSearches()}
            >
              Clear All
            </button>
          </div>
          {recentSearches
            .slice(0, showMore ? recentSearches.length : 5)
            .map((search) => (
              <div
                key={search._id}
                className="flex items-center mb-2 cursor-pointer"
                onClick={() => handleRecentSearchClick(search.query)}
              >
                <AiOutlineClockCircle className="text-gray-500 mr-3" />
                <div className="flex-1">
                  <p className="font-bold text-white">{search.query}</p>
                </div>
                <IoClose
                  className="text-gray-500 cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteRecentSearch(search._id);
                  }}
                />
              </div>
            ))}
          {recentSearches.length > 5 && (
            <button
              className="text-sm text-pink-500 hover:underline"
              onClick={() => setShowMore(!showMore)}
            >
              {showMore ? "Show Less" : "See More"}
            </button>
          )}
        </div>
      )}

      {data && (
        <div className="w-full max-w-lg mt-4">
          {data.users.length > 0 && (
            <>
              <h3 className="text-lg font-semibold mb-2 text-white">Users</h3>
              {data.users.map((user) => (
                <UserCard key={user._id} user={user} />
              ))}
            </>
          )}
          {data.posts.length > 0 && (
            <>
              <h3 className="text-lg font-semibold mt-4 mb-2 text-white">
                Posts
              </h3>
              {data.posts.map((post) => (
                <Post key={post._id} post={post} />
              ))}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default SearchPage;
