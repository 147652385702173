// SplashScreen.jsx
import React from "react";
import VyroVyrosSvg from "../svgs/VyroVyrosSvg";

const SplashScreen = () => {
  return (
    <div className="h-screen flex flex-col justify-between items-center bg-white text-black">
      <div className="flex-1 flex justify-center items-center">
        <div className="flex flex-col items-center">
          <VyroVyrosSvg className="w-24 h-24" />
          <div className="flex mt-4">
            <div className="w-2.5 h-2.5 bg-black rounded-full animate-bounce" />
            <div className="w-2.5 h-2.5 bg-yellow-500 rounded-full animate-bounce delay-150 ml-1" />
            <div className="w-2.5 h-2.5 bg-red-500 rounded-full animate-bounce delay-300 ml-1" />
          </div>
        </div>
      </div>
      <div className="pb-8 flex flex-col items-center">
        <p className="text-black text-3xl ">256 Vyro</p>
        <p className="text-gray-500 text-1xl pb-28">Made in Uganda</p>
      </div>
    </div>
  );
};

export default SplashScreen;
