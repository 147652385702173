import { Link, useNavigate } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import LoadingSpinner from "../../components/common/LoadingSpinner";
import { IoSettingsOutline, IoArrowBack, IoClose } from "react-icons/io5";
import { useState } from "react";

const NotificationPage = ({ authUser }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  // Fetch notifications
  const { data: notifications, isLoading } = useQuery({
    queryKey: ["notifications"],
    queryFn: async () => {
      const res = await fetch("/api/notifications", { credentials: "include" });
      if (res.status === 401) {
        navigate("/login"); // Redirect to login page if unauthorized
        throw new Error("Unauthorized access");
      }
      if (!res.ok) throw new Error("Something went wrong");
      return res.json();
    },
    onError: (error) => toast.error(error.message),
  });

  // Mutation for deleting all notifications
  const { mutate: deleteNotifications } = useMutation({
    mutationFn: async () => {
      const res = await fetch("/api/notifications", { method: "DELETE" });
      if (!res.ok) throw new Error("Something went wrong");
      return res.json();
    },
    onSuccess: () => {
      toast.success("Notifications cleared successfully");
      queryClient.invalidateQueries({ queryKey: ["notifications"] });
    },
    onError: (error) => toast.error(error.message),
  });

  // Mutation for marking a notification as read
  const { mutate: markAsRead } = useMutation({
    mutationFn: async (id) => {
      const res = await fetch(`/api/notifications/${id}/read`, {
        method: "PATCH",
      });
      if (!res.ok) throw new Error("Something went wrong");
      return res.json();
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["notifications"] });
    },
  });

  // Mutation for deleting a single notification
  const { mutate: deleteNotification } = useMutation({
    mutationFn: async (id) => {
      const res = await fetch(`/api/notifications/${id}`, { method: "DELETE" });
      if (!res.ok) throw new Error("Something went wrong");
      return res.json();
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["notifications"] });
    },
    onError: (error) => toast.error(error.message),
  });

  // Handle notification click and mark it as read
  const handleNotificationClick = (notification) => {
    if (!notification.read) {
      // Debounce the markAsRead mutation
      setTimeout(() => markAsRead(notification._id), 300);
    }
    if (notification.type === "like" || notification.type === "comment") {
      navigate(`/post/${notification.post._id}`);
    } else if (notification.type === "follow") {
      navigate(`/profile/${notification.from.username}`);
    }
  };

  // Close the modal when clicking outside of it
  const handleBackdropClick = (e) => {
    if (e.target.id === "backdrop") {
      setIsSettingsOpen(false);
    }
  };

  return (
    <>
      <div className="flex-[4_4_0] border-l pb-16 border-r border-gray-700 min-h-screen">
        <div className="flex justify-between items-center p-4 border-b border-gray-700">
          <button onClick={() => navigate(-1)} className="mr-2 text-white">
            <IoArrowBack className="text-2xl cursor-pointer" />
          </button>
          <p className="font-bold">Notifications</p>
          <button
            className="m-1"
            onClick={() => setIsSettingsOpen(true)}
            aria-label="Open Settings"
          >
            <IoSettingsOutline className="w-6 h-6 text-white" />
          </button>
        </div>

        {/* Show loading spinner while loading notifications */}
        {isLoading && (
          <div className="flex justify-center h-full items-center">
            <LoadingSpinner size="lg" />
          </div>
        )}

        {/* Show message if there are no notifications */}
        {notifications?.length === 0 && (
          <div className="text-center p-4 font-bold">No notifications 🤔</div>
        )}

        {/* Render notifications sorted by newest at the top */}
        {notifications
          ?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
          .map((notification) => (
            <div
              className={`p-4 cursor-pointer ${
                notification.read ? "bg-black" : "bg-gray-900"
              }`}
              key={notification._id}
              onClick={() => handleNotificationClick(notification)}
            >
              <div className="flex gap-2">
                <Link
                  to={`/profile/${notification.from.username}`}
                  className="flex items-center"
                  onClick={(e) => e.stopPropagation()} // Stop propagation when clicking the profile link
                >
                  <div className="avatar">
                    <div className="w-8 rounded-full">
                      <img
                        src={
                          notification.from.profileImg ||
                          "/avatar-placeholder.png"
                        }
                        alt="Profile"
                      />
                    </div>
                  </div>
                </Link>
                <div className="flex-1">
                  <span className="font-bold">
                    {notification.from.fullName ||
                      `@${notification.from.username}`}{" "}
                    {/* Correct field is fullName */}
                  </span>{" "}
                  {notification.type === "follow"
                    ? "followed you"
                    : notification.type === "like"
                    ? "liked your post"
                    : notification.type === "comment"
                    ? "commented on your post"
                    : "reposted your post"}
                </div>

                <div className="ml-auto">
                  <button
                    className="text-gray-500"
                    onClick={(e) => {
                      e.stopPropagation();
                      deleteNotification(notification._id);
                    }}
                    aria-label="Delete notification"
                  >
                    <IoClose />
                  </button>
                </div>
              </div>
            </div>
          ))}

        {/* Settings Modal */}
        {isSettingsOpen && (
          <div
            id="backdrop"
            className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50"
            onClick={handleBackdropClick}
          >
            <div className="bg-gray-900 p-4 rounded-lg shadow-lg w-72">
              <h2 className="font-bold text-lg">Settings</h2>
              <div className="mt-4 flex justify-between">
                <button
                  className="bg-gray-700 text-white px-4 py-2 rounded-md"
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteNotifications();
                    setIsSettingsOpen(false);
                  }}
                >
                  Clear all notifications
                </button>
                <button
                  className=" px-4 py-2 rounded-md"
                  onClick={() => setIsSettingsOpen(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default NotificationPage;
