import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { IoNotifications } from "react-icons/io5";
import VyroName from "../svgs/VyroName";
import { useQuery } from "@tanstack/react-query";

const TopBar = () => {
  const [showTopBar, setShowTopBar] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [unreadCount, setUnreadCount] = useState(0);
  const [showLogo, setShowLogo] = useState(true); // Toggle for logo and "TOP 10"

  // Fetch unread notifications count
  const { data: notifications } = useQuery({
    queryKey: ["notifications"],
    queryFn: async () => {
      const res = await fetch("/api/notifications");
      return res.json();
    },
    staleTime: 0,
    refetchOnWindowFocus: true,
    onSuccess: (data) => {
      const unreadNotifications = data.filter((n) => !n.read).length;
      setUnreadCount(unreadNotifications > 99 ? "99+" : unreadNotifications);
    },
  });

  useEffect(() => {
    setLastScrollY(window.scrollY); // Set initial scroll position after mount

    const handleScroll = () => {
      if (window.scrollY > lastScrollY) {
        setShowTopBar(false);
      } else {
        setShowTopBar(true);
      }
      setLastScrollY(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [lastScrollY]);

  // Toggle between logo and "TOP 10"
  useEffect(() => {
    const logoTimer = setInterval(() => {
      setShowLogo(true);
    }, 70000); // 70 seconds to account for both intervals (60s for logo + 10s for text)

    const textTimer = setInterval(() => {
      setShowLogo(false);
    }, 60000); // Switch to "TOP 10" every 60 seconds

    return () => {
      clearInterval(logoTimer);
      clearInterval(textTimer);
    };
  }, []);

  return (
    <div
      className={`fixed top-0 left-0 right-0 z-50 md:hidden bg-black transition-transform duration-300 ${
        showTopBar ? "translate-y-0" : "-translate-y-full"
      }`}
    >
      <div className="flex justify-between items-center px-4 py-2 border-b border-gray-700">
        <Link to="/top-liked-users" className="flex items-center">
          {showLogo ? (
            <VyroName className="w-8 h-8 rounded-full fill-white transition-transform duration-500 ease-in-out transform -translate-y-2 opacity-0" />
          ) : (
            <span
              className="text-white font-[Rancho] text-4xl transition-transform duration-500 ease-in-out transform translate-y-0 opacity-100"
              style={{ fontFamily: "Rancho, sans-serif", fontSize: "40px" }}
            >
              TOP 10
            </span>
          )}
        </Link>

        {/* Notifications Link */}
        <Link to="/notifications" className="relative">
          <IoNotifications className="w-6 h-6 text-white" />
          {unreadCount > 0 && (
            <span className="absolute -top-1 -right-2 bg-red-600 text-white text-xs rounded-full w-5 h-5 flex items-center justify-center">
              {unreadCount}
            </span>
          )}
        </Link>
      </div>
    </div>
  );
};

export default TopBar;
