import React, { useEffect, useRef, useState } from "react";
import {
  FaHeart,
  FaRegHeart,
  FaPlay,
  FaPause,
  FaVolumeMute,
  FaVolumeUp,
} from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import CommentSection from "./CommentSection";
import { HiChatBubbleOvalLeftEllipsis } from "react-icons/hi2";
import ShareModal from "./ShareModal";
import { RiShareForwardFill } from "react-icons/ri";

const Vyro = ({
  vyro,
  index,
  initialVyroId,
  handleLikeVyro,
  authUser,
  videoRefs,
  setIsCommentModalOpen,
  isLastVyro,
  isMutedGlobal,
  setIsMutedGlobal,
}) => {
  const [isCommentModalOpen, setIsCommentModalOpenLocal] = useState(false);
  const [commentCount, setCommentCount] = useState(vyro.comments.length);
  const [shareCount, setShareCount] = useState(vyro.shares || 0);
  const [showControls, setShowControls] = useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [isCaptionExpanded, setIsCaptionExpanded] = useState(false); // State for "See more"
  const videoRef = useRef();
  const navigate = useNavigate();

  const maxCaptionLength = 100; // Maximum length before showing "See more"

  useEffect(() => {
    videoRefs.current[index] = videoRef.current;
    if (vyro._id === initialVyroId && videoRef.current) {
      videoRef.current.play();
    }
    if (videoRef.current) {
      videoRef.current.muted = isMutedGlobal;
    }
  }, [index, initialVyroId, vyro._id, videoRefs, isMutedGlobal]);

  const handleCommentClick = (e) => {
    e.stopPropagation();
    setIsCommentModalOpen(true);
    setIsCommentModalOpenLocal(true);
  };

  const handleCloseCommentSection = () => {
    setIsCommentModalOpen(false);
    setIsCommentModalOpenLocal(false);
  };

  useEffect(() => {
    if (isCommentModalOpen) {
      window.scrollTo(0, 0);
      window.onscroll = () => window.scrollTo(0, 0);
    } else {
      window.onscroll = null;
    }
    return () => {
      window.onscroll = null;
    };
  }, [isCommentModalOpen]);

  const handleCommentAdded = () => {
    setCommentCount((prevCount) => prevCount + 1);
  };

  useEffect(() => {
    // Load mute state from localStorage on component mount
    const storedMuteState = localStorage.getItem("isMutedGlobal");
    if (storedMuteState !== null) {
      setIsMutedGlobal(storedMuteState === "true");
    }
  }, [setIsMutedGlobal]);

  const handleMuteToggle = (e) => {
    e.stopPropagation();
    const newMuteState = !isMutedGlobal;
    setIsMutedGlobal(newMuteState);
    localStorage.setItem("isMutedGlobal", newMuteState); // Store mute state in localStorage
  };

  const togglePlayPause = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
    }
    setShowControls(!showControls);
  };

  const handleModalClick = (e) => {
    e.stopPropagation();
  };

  const handleModalOutsideClick = (e) => {
    e.stopPropagation();
    setIsCommentModalOpenLocal(false);
    setIsShareModalOpen(false);
  };

  const handleShareSuccess = () => {
    setShareCount((prevCount) => prevCount + 1);
  };

  const handleShareAction = async (platform) => {
    try {
      const res = await fetch(`/api/posts/${vyro._id}/shares`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (res.ok) {
        handleShareSuccess();
        toast.success(`Shared to ${platform}!`);
      } else {
        throw new Error("Failed to share");
      }
    } catch (error) {
      toast.error("Could not share the vyro.");
    }
  };

  useEffect(() => {
    const vyrosContainer = videoRef.current?.parentElement;
    if (!vyrosContainer) return;

    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        const video = entry.target;
        if (entry.isIntersecting) {
          video.play();
        } else {
          video.pause();
          setShowControls(false);
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.5,
    });

    videoRefs.current.forEach((video) => {
      if (video) {
        observer.observe(video);
      }
    });

    return () => {
      observer.disconnect();
    };
  }, [videoRefs]);

  // Prevent background scrolling when ShareModal is open
  useEffect(() => {
    if (isShareModalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto"; // Reset on cleanup
    };
  }, [isShareModalOpen]);

  // Toggle caption expansion
  const toggleCaptionExpansion = () => {
    setIsCaptionExpanded(!isCaptionExpanded);
  };

  // Navigate to full post when "See more" is clicked
  const handleSeeMoreClick = (e) => {
    e.stopPropagation();
    navigate(`/post/${vyro._id}`);
  };

  return (
    <div
      key={vyro._id}
      className={`vyro ${initialVyroId === vyro._id ? "initial-vyro" : ""}`}
      onClick={togglePlayPause}
      ref={isLastVyro}
    >
      <div className="video-container">
        <video
          ref={videoRef}
          src={vyro.media}
          data-id={vyro._id}
          className="video-content"
          autoPlay={false}
          loop
          muted={isMutedGlobal}
          playsInline
          webkit-playsinline="true"
          controls={false}
          disablePictureInPicture // Blocks picture-in-picture mode
          controlsList="nodownload nofullscreen noremoteplayback" // Blocks fullscreen
          style={{ pointerEvents: "none" }} // CSS to disable any user interaction
        />

        {showControls && (
          <>
            <div className="absolute inset-0 flex items-center justify-center">
              <button className="text-white text-4xl">
                {videoRef.current?.paused ? <FaPlay /> : <FaPause />}
              </button>
            </div>
            <div className="absolute bottom-0 left-0 w-full px-4 pb-4">
              <div className="w-full bg-gray-800 bg-opacity-50 h-1">
                <div
                  className="bg-white h-1"
                  style={{
                    width: `${
                      (videoRef.current?.currentTime /
                        videoRef.current?.duration) *
                      100
                    }%`,
                  }}
                />
              </div>
            </div>
          </>
        )}
      </div>
      <div className="vyro-content mr-12">
        <h3 className="text-xl font-bold">{vyro.user.username}</h3>
        <p className="text-sm">
          {isCaptionExpanded || vyro.text.length <= maxCaptionLength
            ? vyro.text
            : `${vyro.text.substring(0, maxCaptionLength)} `}
          {vyro.text.length > maxCaptionLength && (
            <span
              onClick={handleSeeMoreClick}
              style={{ color: "white", cursor: "pointer" }}
            >
              {isCaptionExpanded ? "See less" : "...  See more"}
            </span>
          )}
        </p>
      </div>
      <div className="vyro-actions">
        <div className="avatar mb-2">
          <Link
            to={`/profile/${vyro.user.username}`}
            className="w-12 h-12 rounded-full overflow-hidden border border-white"
          >
            <img
              src={vyro.user.profileImg || "/avatar-placeholder.png"}
              alt={`${vyro.user.username}'s avatar`}
            />
          </Link>
        </div>
        <button
          className="flex flex-col items-center"
          onClick={(e) => {
            e.stopPropagation();
            handleLikeVyro(vyro._id, e);
          }}
        >
          {authUser && vyro.likes.includes(authUser._id) ? (
            <FaHeart className="text-3xl text-pink-500" />
          ) : (
            <FaRegHeart className="text-3xl" />
          )}
          <span>{vyro.likes.length}</span>
        </button>
        <button
          className="flex flex-col items-center"
          onClick={(e) => {
            e.stopPropagation();
            handleCommentClick(e);
          }}
        >
          <HiChatBubbleOvalLeftEllipsis className="text-4xl" />
          <span>{commentCount}</span>
        </button>

        <button
          className="flex flex-col items-center"
          onClick={(e) => {
            e.stopPropagation();
            setIsShareModalOpen(true);
          }}
        >
          <RiShareForwardFill className="text-4xl" />
          <span>{shareCount}</span>
        </button>

        <button
          className="mute-button flex flex-col items-center mt-2"
          onClick={(e) => {
            e.stopPropagation();
            handleMuteToggle(e);
          }}
        >
          {isMutedGlobal ? <FaVolumeMute /> : <FaVolumeUp />}
        </button>
      </div>

      {isCommentModalOpen && (
        <div
          className="modal-overlay fixed inset-0 z-50 bg-black bg-opacity-50"
          onClick={handleModalOutsideClick}
        >
          <div className="modal-container" onClick={handleModalClick}>
            <CommentSection
              post={vyro}
              isCommentModalOpen={isCommentModalOpen}
              setIsCommentModalOpen={setIsCommentModalOpenLocal}
              className="comment-section-content"
              onCommentAdded={handleCommentAdded}
              onClose={handleCloseCommentSection}
            />
          </div>
        </div>
      )}

      {isShareModalOpen && (
        <div
          className="modal-overlay fixed inset-0 z-50 bg-black bg-opacity-50"
          onClick={handleModalOutsideClick}
        >
          <div className="modal-container" onClick={handleModalClick}>
            <ShareModal
              isOpen={isShareModalOpen}
              onClose={() => setIsShareModalOpen(false)}
              postId={vyro._id}
              onShareSuccess={handleShareSuccess}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Vyro;
