import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";
import { useQuery } from "@tanstack/react-query";
import useFollow from "../../hooks/useFollow";
import LoadingSpinner from "./LoadingSpinner";

const TopLikedUsersPage = () => {
  const [topUsers, setTopUsers] = useState([]);
  const [loadingUserId, setLoadingUserId] = useState(null);
  const navigate = useNavigate();

  const { data: authUser } = useQuery({
    queryKey: ["authUser"],
    queryFn: async () => {
      const res = await fetch("/api/auth/me", { credentials: "include" });
      const data = await res.json();
      if (!res.ok) throw new Error(data.error || "Failed to fetch user");
      return data;
    },
  });

  useEffect(() => {
    const fetchTopUsers = async () => {
      try {
        const response = await axios.get("/api/posts/top-liked-users");
        setTopUsers(response.data.topUsers);
      } catch (error) {
        console.error("Error fetching top liked users:", error);
      }
    };

    fetchTopUsers();
  }, []);

  const { follow, isPending } = useFollow();

  const handleFollow = async (userId) => {
    setLoadingUserId(userId);
    await follow(userId);
    setLoadingUserId(null);
  };

  return (
    <div className="top-users-container">
      <div className="flex items-center justify-center mb-4 relative">
        <button
          onClick={() => navigate(-1)}
          className="text-white absolute left-0"
        >
          <IoArrowBack className="text-2xl cursor-pointer" />
        </button>
        <h2 className="font-bold  text-center">256 Vyro Leaderboard</h2>
        <span className="absolute right-0 text-gray-400 font-thin">Likes</span>
      </div>

      <div className="flex justify-center items-center">
        <ul className="top-users-list w-full max-w-sm space-y-2 sm:max-w-md">
          {topUsers.map((user, index) => (
            <li
              key={user.userId}
              className="top-user-item flex items-center justify-between p-1"
            >
              <div className="flex items-center space-x-2">
                <div className="top-user-rank text-base font-bold text-white sm:text-lg">
                  {index + 1}
                </div>
                <Link
                  to={`/profile/${user.userDetails.username}`}
                  className="w-8 h-8 sm:w-10 sm:h-10 rounded-full overflow-hidden flex items-center justify-center"
                >
                  <img
                    src={user.userDetails.profileImg}
                    alt={user.userDetails.fullName}
                    className="profile-img w-full h-full object-cover"
                  />
                </Link>
                <div className="user-details flex flex-col">
                  <Link
                    to={`/profile/${user.userDetails.username}`}
                    className="username text-xs font-medium text-white sm:text-sm"
                  >
                    {user.userDetails.fullName}
                  </Link>
                </div>
              </div>
              <div className="flex items-center">
                {authUser?._id !== user.userId &&
                  !authUser?.following.includes(user.userId) && (
                    <button
                      className="btn bg-white text-black hover:bg-white hover:opacity-90 rounded-full btn-sm mr-4"
                      onClick={(e) => {
                        e.preventDefault();
                        handleFollow(user.userId);
                      }}
                    >
                      {loadingUserId === user.userId ? (
                        <LoadingSpinner size="sm" />
                      ) : (
                        "Follow"
                      )}
                    </button>
                  )}
                <div className="total-likes text-sm font-semibold text-white sm:text-sm text-right">
                  {user.totalLikes}
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default TopLikedUsersPage;
