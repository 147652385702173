import { useState, useEffect, useRef } from "react";
import {
  useMutation,
  useQuery,
  useInfiniteQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { Link } from "react-router-dom"; // Import Link for routing
import toast from "react-hot-toast";
import { MdClose } from "react-icons/md";
import { RiSendPlaneFill } from "react-icons/ri";
import LoadingSpinner from "./LoadingSpinner";
import { formatPostDate } from "../../utils/date";
import { FaRegHeart, FaHeart } from "react-icons/fa";

const CommentSection = ({
  post,
  isCommentModalOpen,
  setIsCommentModalOpen,
  onCommentAdded,
  onCommentDeleted,
  onClose,
}) => {
  const [comment, setComment] = useState("");
  const [localComments, setLocalComments] = useState([]);
  const [isPostingComment, setIsPostingComment] = useState(false);
  const [longPressedCommentId, setLongPressedCommentId] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const touchTimer = useRef(null);
  const queryClient = useQueryClient();
  const { data: authUser } = useQuery({ queryKey: ["authUser"] });

  useEffect(() => {
    setLocalComments(post.comments || []);
  }, [post.comments]);

  const fetchComments = async ({ pageParam = 1 }) => {
    const res = await fetch(
      `/api/posts/comments/${post._id}?page=${pageParam}`
    );
    const data = await res.json();
    if (!res.ok) {
      throw new Error(data.error || "Something went wrong");
    }
    return data;
  };

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery({
      queryKey: ["comments", post._id],
      queryFn: fetchComments,
      getNextPageParam: (lastPage, pages) => {
        if (lastPage.hasNextPage) {
          return pages.length + 1;
        }
        return undefined;
      },
      onSuccess: (data) => {
        setLocalComments(data.pages.flatMap((page) => page.comments));
      },
    });

  const { mutate: commentPost } = useMutation({
    mutationFn: async () => {
      setIsPostingComment(true);
      const res = await fetch(`/api/posts/comment/${post._id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ text: comment }),
      });
      const data = await res.json();

      if (!res.ok) {
        throw new Error(data.error || "Something went wrong");
      }
      return data;
    },
    onSuccess: (newCommentFromServer) => {
      setLocalComments((prevComments) => [
        newCommentFromServer,
        ...prevComments,
      ]);
      setComment("");
      toast.success("Comment posted successfully");
      if (onCommentAdded) onCommentAdded();
    },
    onError: () => {
      toast.error("Something went wrong");
    },
    onSettled: () => {
      setIsPostingComment(false);
      queryClient.invalidateQueries({ queryKey: ["comments", post._id] });
    },
  });

  const handlePostComment = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!comment.trim()) {
      toast.error("Comment cannot be empty");
      return;
    }
    commentPost();
  };

  const loadMoreComments = () => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  };

  if (!authUser) return null;

  const { mutate: likeComment } = useMutation({
    mutationFn: async (commentId) => {
      const res = await fetch(
        `/api/posts/${post._id}/comments/${commentId}/like`,
        {
          method: "POST",
        }
      );
      if (!res.ok) throw new Error("Something went wrong");
      return res.json();
    },
    onSuccess: (data, commentId) => {
      setLocalComments((prev) =>
        prev.map((comment) =>
          comment._id === commentId
            ? { ...comment, likes: data.likes }
            : comment
        )
      );
    },
  });

  const { mutate: deleteComment } = useMutation({
    mutationFn: async (commentId) => {
      const res = await fetch(`/api/posts/${post._id}/comments/${commentId}`, {
        method: "DELETE",
      });
      if (!res.ok) throw new Error("Something went wrong");
      return res.json();
    },
    onSuccess: (data, commentId) => {
      toast.success("Comment deleted successfully");
      setLocalComments((prev) =>
        prev.filter((comment) => comment._id !== commentId)
      );
      if (onCommentDeleted) onCommentDeleted();
    },
  });

  const handleTouchStart = (comment) => {
    if (!authUser) return; // Ensure authUser is defined
    if (authUser._id === comment.user._id) {
      touchTimer.current = setTimeout(() => {
        setLongPressedCommentId(comment._id);
        setIsDeleteModalOpen(true);
      }, 600);
    }
  };

  const handleTouchEnd = () => {
    if (touchTimer.current) clearTimeout(touchTimer.current);
  };

  const handleDeleteComment = () => {
    if (longPressedCommentId) {
      deleteComment(longPressedCommentId);
      setIsDeleteModalOpen(false);
    }
  };

  const handleLikeComment = (commentId) => {
    if (commentId) {
      likeComment(commentId);
    } else {
      toast.error("Comment ID is missing");
    }
  };

  if (!authUser) {
    return <div>Loading...</div>; // Display loading state instead of returning null
  }

  return (
    <>
      {isCommentModalOpen && (
        <div
          className="fixed inset-0 z-50 flex items-end md:items-center justify-center bg-black bg-opacity-50 comment-modal"
          onClick={() => setIsCommentModalOpen(false)}
        >
          <div
            className="w-full md:w-1/2 bg-secondary rounded-t-lg md:rounded-lg max-h-screen overflow-hidden comment-modal-content"
            style={{ position: "relative" }}
            onClick={(e) => e.stopPropagation()}
          >
            <div className="flex justify-between items-center p-4 border-b border-gray-700">
              <h3 className="font-normal text-center text-white">
                Comment Section
              </h3>
              <button
                className="text-white"
                onClick={() => setIsCommentModalOpen(false)}
              >
                <MdClose />
              </button>
            </div>
            <div
              className="flex flex-col gap-3 overflow-y-auto p-4 comment-list"
              style={{ maxHeight: "50vh" }}
              onScroll={(e) => {
                if (
                  e.target.scrollHeight - e.target.scrollTop ===
                  e.target.clientHeight
                ) {
                  loadMoreComments();
                }
              }}
              onClick={(e) => e.stopPropagation()}
            >
              {localComments.length === 0 && (
                <p className="text-sm text-slate-500">
                  No comments, be the first one
                </p>
              )}
              {localComments.map((comment) => (
                <div
                  key={comment._id}
                  className="flex gap-2 items-start w-full"
                  onTouchStart={() => handleTouchStart(comment)}
                  onTouchEnd={handleTouchEnd}
                  onContextMenu={(e) => {
                    e.preventDefault();
                    if (comment.user._id === authUser._id) {
                      handleTouchStart(comment);
                    }
                  }}
                >
                  <Link
                    to={`/profile/${comment.user.username}`}
                    className="w-8 h-8 rounded-full overflow-hidden flex items-center justify-center"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <img
                      src={comment.user.profileImg || "/avatar-placeholder.png"}
                      alt={`${comment.user.username}'s avatar`}
                      className="w-full h-full object-cover"
                    />
                  </Link>

                  <div className="flex-grow">
                    <div className="flex flex-col">
                      <div className="flex items-center gap-1">
                        <Link
                          to={`/profile/${comment.user.username}`}
                          className="font-normal text-gray-500"
                          onClick={(e) => e.stopPropagation()}
                        >
                          {comment.user.fullName}
                        </Link>
                      </div>
                      <div className="text-sm text-white">{comment.text}</div>
                      <span className="text-xs mt-2 text-gray-500">
                        {formatPostDate(comment.createdAt)}
                      </span>
                    </div>
                  </div>

                  <div className="flex items-center gap-2">
                    <div
                      className="cursor-pointer text-xs text-gray-500"
                      onClick={() => handleLikeComment(comment._id)}
                    >
                      {(comment.likes || []).includes(authUser._id) ? (
                        <FaHeart className="text-pink-500 text-lg" />
                      ) : (
                        <FaRegHeart className="text-slate-500 text-lg" />
                      )}
                      <span className="ml-1 text-sm">
                        {(comment.likes || []).length}
                      </span>
                    </div>
                  </div>
                </div>
              ))}

              {isFetchingNextPage && (
                <div className="flex justify-center">
                  <LoadingSpinner size="md" />
                </div>
              )}
            </div>
            <form
              className="flex gap-2 items-center p-4 border-t border-gray-700"
              onSubmit={handlePostComment}
              onClick={(e) => e.stopPropagation()}
            >
              <textarea
                className="textarea-xs w-full p-2 rounded-xl text-sm resize-none focus:outline-none bg-black text-white"
                placeholder="Add comment..."
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
              <button className="btn btn-primary rounded-full btn-sm text-white px-4">
                {isPostingComment ? (
                  <LoadingSpinner size="md" />
                ) : (
                  <RiSendPlaneFill />
                )}
              </button>
            </form>
          </div>
        </div>
      )}

      {isDeleteModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div
            className="bg-secondary rounded-lg p-6"
            onClick={(e) => e.stopPropagation()}
          >
            <h3 className="text-white text-center mb-4">
              Are you sure you want to delete this comment?
            </h3>
            <div className="flex justify-center gap-4">
              <button className="btn btn-danger" onClick={handleDeleteComment}>
                Delete
              </button>
              <button
                className="btn btn-secondary"
                onClick={() => setIsDeleteModalOpen(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CommentSection;
