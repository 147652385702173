import { FaRegImage } from "react-icons/fa6";
import { IoCloseSharp, IoCameraReverse } from "react-icons/io5";
import { useRef, useState, useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../components/common/LoadingSpinner";

const CreatePost = () => {
  const [text, setText] = useState("");
  const [media, setMedia] = useState(null);
  const [postType, setPostType] = useState("text");
  const [showCameraOverlay, setShowCameraOverlay] = useState(false);
  const [mode, setMode] = useState("photo");
  const [previewMedia, setPreviewMedia] = useState(null);
  const [recording, setRecording] = useState(false);
  const [recordingTime, setRecordingTime] = useState(0);
  const [facingMode, setFacingMode] = useState("user"); // Front camera by default
  const mediaRef = useRef(null);
  const videoRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const recordingIntervalRef = useRef(null);
  const navigate = useNavigate();

  const { data: authUser } = useQuery({ queryKey: ["authUser"] });
  const queryClient = useQueryClient();

  const MAX_PHOTO_SIZE = 10 * 1024 * 1024; // 10MB
  const MAX_VIDEO_SIZE = 100 * 1024 * 1024; // 100MB
  const MAX_VIDEO_DURATION = 60; // 60 seconds

  const {
    mutate: startVideoUpload,
    isPending: isVideoUploading,
    isError,
    error,
  } = useMutation({
    mutationFn: async ({ text, media, postType }) => {
      const formData = new FormData();
      formData.append("text", text);
      formData.append("postType", postType);
      if (media) formData.append("media", media);

      const res = await fetch("/api/posts/start-video-upload", {
        method: "POST",
        body: formData,
      });
      const data = await res.json();
      if (!res.ok) {
        throw new Error(data.error || "Something went wrong");
      }
      return data;
    },
    onSuccess: () => {
      setText("");
      setMedia(null);
      setPostType("text");
      toast.success(
        "Your post is being processed. It will be published shortly."
      );
      queryClient.invalidateQueries({ queryKey: ["posts"] });
      navigate(-1);
    },
  });

  const { mutate: uploadImage, isPending: isImageUploading } = useMutation({
    mutationFn: async ({ text, media, postType }) => {
      const formData = new FormData();
      formData.append("text", text);
      formData.append("postType", postType);
      if (media) formData.append("media", media);

      const res = await fetch("/api/posts/create", {
        method: "POST",
        body: formData,
      });
      const data = await res.json();
      if (!res.ok) {
        throw new Error(data.error || "Something went wrong");
      }
      return data;
    },
    onSuccess: () => {
      setText("");
      setMedia(null);
      setPostType("text");
      toast.success("Your post has been created.");
      queryClient.invalidateQueries({ queryKey: ["posts"] });
      navigate(-1);
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!text && !media) {
      toast.error("Post must contain text or media.");
      return;
    }

    if (postType === "video") {
      startVideoUpload({ text, media, postType });
    } else if (postType === "image") {
      uploadImage({ text, media, postType });
    } else if (!media && text) {
      uploadImage({ text, media: null, postType: "text" });
    }
  };

  const handleMediaChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.type.startsWith("image")) {
        if (file.size > MAX_PHOTO_SIZE) {
          toast.error("Photo size exceeds the 10MB limit.");
          return;
        }
        setMedia(file);
        setPostType("image");
        setShowCameraOverlay(false);
      } else if (file.type.startsWith("video")) {
        if (file.size > MAX_VIDEO_SIZE) {
          toast.error("Video size exceeds the 100MB limit.");
          return;
        }
        setMedia(file);
        setPostType("video");
        setShowCameraOverlay(false);
      } else {
        toast.error("Unsupported file type. Please select an image or video.");
        setMedia(null);
        mediaRef.current.value = null;
        setPostType("text");
      }
    }
  };

  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          facingMode,
          width: { ideal: 1280 }, // Set ideal width for higher quality
          height: { ideal: 720 }, // Set ideal height for higher quality
          frameRate: { ideal: 30 }, // Aim for 30 FPS for smoother video
        },
      });

      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.play();

        // Apply CSS transform to mirror front camera
        videoRef.current.style.transform =
          facingMode === "user" ? "scaleX(-1)" : "scaleX(1)";
      }
    } catch (error) {
      console.error("Error accessing camera:", error);
      toast.error(
        `Failed to access ${
          facingMode === "user" ? "front" : "rear"
        } camera. Switching to default camera.`
      );

      if (facingMode === "environment") {
        try {
          const fallbackStream = await navigator.mediaDevices.getUserMedia({
            video: {
              facingMode: "user",
              width: { ideal: 1280 },
              height: { ideal: 720 },
            },
          });

          if (videoRef.current) {
            videoRef.current.srcObject = fallbackStream;
            videoRef.current.play();
            videoRef.current.style.transform = "scaleX(-1)"; // Mirror for front camera
          }

          setFacingMode("user");
        } catch (fallbackError) {
          console.error("Fallback to front camera also failed:", fallbackError);
          toast.error(
            "Failed to access any camera. Please check permissions and try again."
          );
        }
      }
    }
  };

  // Stop the camera stream
  const stopCameraStream = () => {
    if (videoRef.current && videoRef.current.srcObject) {
      videoRef.current.srcObject.getTracks().forEach((track) => track.stop());
      videoRef.current.srcObject = null; // Clear the source to prevent warnings
    }
  };

  useEffect(() => {
    if (showCameraOverlay) {
      startCamera();
    }

    // Ensure camera stops when component unmounts
    return () => {
      stopCameraStream();
    };
  }, [showCameraOverlay, facingMode]);

  // Stop camera stream on page unload or navigate away
  useEffect(() => {
    const handleBeforeUnload = () => stopCameraStream();
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Clean up when navigating away or refreshing the page
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      stopCameraStream();
    };
  }, []);

  const toggleMode = (newMode) => {
    setMode(newMode);
    if (newMode === "photo") {
      mediaRef.current.setAttribute("accept", "image/*");
    } else {
      mediaRef.current.setAttribute("accept", "video/*");
    }
  };

  const toggleCamera = () => {
    setFacingMode((prevMode) => (prevMode === "user" ? "environment" : "user"));
  };

  const openGallery = () => {
    mediaRef.current.setAttribute("accept", "image/*,video/*");
    mediaRef.current.click();
  };

  const capturePhoto = () => {
    if (videoRef.current) {
      const canvas = document.createElement("canvas");
      canvas.width = videoRef.current.videoWidth;
      canvas.height = videoRef.current.videoHeight;
      const context = canvas.getContext("2d");

      // Flip the canvas horizontally if using the front camera
      if (facingMode === "user") {
        context.translate(canvas.width, 0);
        context.scale(-1, 1);
      }
      context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);

      canvas.toBlob((blob) => {
        setPreviewMedia(blob);
        setPostType("image");
      });
    }
  };

  const startRecording = () => {
    if (videoRef.current) {
      const stream = videoRef.current.srcObject;
      mediaRecorderRef.current = new MediaRecorder(stream, {
        mimeType: "video/webm",
      });
      const chunks = [];
      mediaRecorderRef.current.ondataavailable = (e) => chunks.push(e.data);
      mediaRecorderRef.current.onstop = () => {
        const blob = new Blob(chunks, { type: "video/webm" });
        setPreviewMedia(blob);
        setPostType("video");
      };
      mediaRecorderRef.current.start();
      setRecording(true);
      setRecordingTime(0);

      recordingIntervalRef.current = setInterval(() => {
        setRecordingTime((prevTime) => {
          if (prevTime >= MAX_VIDEO_DURATION) {
            stopRecording();
            return prevTime;
          }
          return prevTime + 1;
        });
      }, 1000);

      setTimeout(() => {
        if (mediaRecorderRef.current && recording) {
          stopRecording();
        }
      }, MAX_VIDEO_DURATION * 1000);
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && recording) {
      mediaRecorderRef.current.stop();
      setRecording(false);
      clearInterval(recordingIntervalRef.current);
    }
  };

  const isUploading = isVideoUploading || isImageUploading;

  return (
    <div className="container mx-auto pb-16 p-2">
      <div className="p-2 relative">
        <IoCloseSharp
          className="absolute top-2 right-2 text-white bg-gray-800 rounded-full w-5 h-5 cursor-pointer"
          onClick={() => {
            stopCameraStream();
            navigate(-1);
          }}
        />
        <h3 className="font-bold text-xl mb-4">New Post</h3>
        <div className="flex p-2 items-start gap-2">
          <div className="avatar">
            <div className="w-8 rounded-full">
              <img src={authUser.profileImg || "/avatar-placeholder.png"} />
            </div>
          </div>
          <form className="flex flex-col gap-2 w-full" onSubmit={handleSubmit}>
            <textarea
              className="textarea w-full p-1 rounded text-md resize-none border focus:outline-none border-gray-800"
              placeholder="What is happening?!"
              value={text}
              onChange={(e) => setText(e.target.value)}
              disabled={isUploading}
            />
            {media && (
              <div className="relative w-full max-w-xs mx-auto">
                <IoCloseSharp
                  className="absolute top-0 right-0 text-white bg-gray-800 rounded-full w-5 h-5 cursor-pointer"
                  onClick={() => {
                    setMedia(null);
                    mediaRef.current.value = null;
                    setPostType("text");
                  }}
                />
                {postType === "image" ? (
                  <img
                    src={URL.createObjectURL(media)}
                    className="w-full h-auto object-contain rounded"
                  />
                ) : (
                  <video
                    ref={videoRef}
                    className="w-full h-auto object-contain rounded"
                    muted
                    autoPlay
                    loop
                  >
                    <source src={URL.createObjectURL(media)} />
                  </video>
                )}
              </div>
            )}

            <div className="flex justify-between border-t py-2 border-t-gray-700">
              <label
                className="cursor-pointer"
                onClick={() => setShowCameraOverlay(true)}
              >
                <FaRegImage
                  className={`fill-white w-6 h-6 ${
                    isUploading ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                />
              </label>
              <input
                type="file"
                accept="image/*,video/*"
                ref={mediaRef}
                onChange={handleMediaChange}
                disabled={isUploading}
                style={{ display: "none" }}
                id="mediaInput"
              />
              <button
                className="btn btn-primary rounded-full btn-sm text-white px-4"
                disabled={isUploading}
              >
                {isUploading ? <LoadingSpinner size="md" /> : "Post"}
              </button>
            </div>
            {isError && <div className="text-red-500">{error.message}</div>}
          </form>
        </div>
      </div>

      {/* Full-Screen Camera Overlay */}
      {showCameraOverlay && (
        <div className="fixed inset-0 bg-black bg-opacity-80 flex flex-col items-center justify-end z-50">
          {/* Close icon */}
          <IoCloseSharp
            className="absolute top-4 left-4 text-white text-4xl cursor-pointer p-2 rounded-full"
            onClick={() => setShowCameraOverlay(false)}
          />

          <video
            ref={videoRef}
            className="absolute inset-0 object-cover w-full h-full"
          />

          {/* Bottom Controls */}
          <div className="absolute bottom-0 w-full p-4 bg-gradient-to-t from-black to-transparent">
            <div className="flex items-center justify-center gap-6 mb-4">
              <button
                onClick={() => toggleMode("photo")}
                className={`p-2 rounded-2xl ${
                  mode === "photo" ? "bg-white text-black" : "bg-gray-800"
                }`}
              >
                Photo
              </button>
              <button
                onClick={() => toggleMode("video")}
                className={`p-2 rounded-2xl ${
                  mode === "video" ? "bg-white text-black" : "bg-gray-800"
                }`}
              >
                Video
              </button>
              <button
                onClick={openGallery}
                className="p-2 rounded-full bg-gray-800"
              >
                <FaRegImage size={24} />
              </button>
            </div>

            {/* Camera Flip Button */}
            <button
              onClick={toggleCamera}
              className="absolute top-4 right-4 text-white text-2xl p-2 rounded-full cursor-pointer"
            >
              <IoCameraReverse />
            </button>

            {/* Shutter Button and Timer */}
            <div className="flex flex-col items-center">
              {recording && (
                <span className="text-red-500 font-bold mb-2">
                  {recordingTime} s
                </span>
              )}
              <button
                className={`p-4 rounded-full w-16 h-16 flex items-center justify-center ${
                  recording ? "bg-red-500 animate-pulse" : "bg-white"
                }`}
                onClick={
                  mode === "photo"
                    ? capturePhoto
                    : recording
                    ? stopRecording
                    : startRecording
                }
              >
                <div
                  className={`w-8 h-8 rounded-full ${
                    recording ? "bg-red-700" : "bg-black"
                  } transition-all duration-200`}
                ></div>
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Preview Mode */}
      {previewMedia && (
        <div className="fixed inset-0 bg-black flex flex-col items-center justify-center z-50">
          <IoCloseSharp
            className="absolute top-4 right-4 text-white text-4xl cursor-pointer bg-gray-800 p-2 rounded-full"
            onClick={() => setPreviewMedia(null)}
          />
          {postType === "image" ? (
            <img
              src={URL.createObjectURL(previewMedia)}
              className="w-full h-auto object-contain"
            />
          ) : (
            <video
              src={URL.createObjectURL(previewMedia)}
              className="w-full h-auto"
              autoPlay
              loop
              muted
            />
          )}
          <div className="flex justify-center gap-4 mt-4">
            <button
              className="btn btn-outline"
              onClick={() => setPreviewMedia(null)}
            >
              Retake
            </button>
            <button
              className="btn btn-primary"
              onClick={() => {
                setMedia(previewMedia);
                setShowCameraOverlay(false);
                setPreviewMedia(null);
              }}
            >
              Continue
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreatePost;
