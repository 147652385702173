import { Link, useLocation } from "react-router-dom";
import { MdHomeFilled } from "react-icons/md";
import { IoNotifications } from "react-icons/io5";
import { FaUser } from "react-icons/fa";
import { HiOutlinePlusCircle } from "react-icons/hi";
import { useMutation } from "@tanstack/react-query";
import { BiLogOut } from "react-icons/bi";
import { useQuery } from "@tanstack/react-query";
import { useQueryClient } from "@tanstack/react-query";
import { FiSearch } from "react-icons/fi";
import { MdOutlineOndemandVideo } from "react-icons/md";

const SidebarMenu = () => {
  const location = useLocation();
  const queryClient = useQueryClient();
  const { mutate: logout } = useMutation({
    mutationFn: async () => {
      const res = await fetch("/api/auth/logout", {
        method: "POST",
      });
      if (!res.ok) {
        const errorData = await res.json();
        throw new Error(
          errorData.error || "Something went wrong during logout"
        );
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["authUser"] });
      navigate("/login");
    },
    onError: (error) => {
      console.error("Logout failed:", error.message);
      toast.error(error.message || "Logout failed");
    },
  });

  const { data: authUser } = useQuery({ queryKey: ["authUser"] });

  return (
    <div className="hidden md:flex md:flex-[2_2_0] w-18 max-w-52">
      <div className="flex flex-col h-screen border-r border-gray-700 w-20 md:w-full">
        <ul className="flex flex-col gap-3 mt-4">
          <li className="flex justify-center md:justify-start">
            <Link
              to="/"
              className="flex gap-3 items-center hover:bg-stone-900 transition-all rounded-full duration-300 py-2 pl-2 pr-4 max-w-fit cursor-pointer"
            >
              <MdHomeFilled
                className={`w-6 h-6 ${
                  location.pathname === "/" ? "text-pink-500" : "text-white"
                }`}
              />
              <span
                className={`text-lg hidden lg:block ${
                  location.pathname === "/" ? "text-pink-500" : "text-white"
                }`}
              >
                Home
              </span>
            </Link>
          </li>
          <li>
            <Link
              to="/vyros"
              className="flex gap-3 items-center hover:bg-stone-900 transition-all rounded-full duration-300 py-2 pl-2 pr-4 max-w-fit cursor-pointer"
            >
              <MdOutlineOndemandVideo
                className={`w-6 h-6 ${
                  location.pathname === "/vyros"
                    ? "text-pink-500"
                    : "text-white"
                }`}
              />
              <span
                className={`text-lg hidden lg:block ${
                  location.pathname === "/vyros"
                    ? "text-pink-500"
                    : "text-white"
                }`}
              >
                Vyros
              </span>
            </Link>
          </li>
          <li className="flex justify-center md:justify-start">
            <Link
              to="/notifications"
              className="flex gap-3 items-center hover:bg-stone-900 transition-all rounded-full duration-300 py-2 pl-2 pr-4 max-w-fit cursor-pointer"
            >
              <IoNotifications
                className={`w-6 h-6 ${
                  location.pathname === "/notifications"
                    ? "text-pink-500"
                    : "text-white"
                }`}
              />
              <span
                className={`text-lg hidden lg:block ${
                  location.pathname === "/notifications"
                    ? "text-pink-500"
                    : "text-white"
                }`}
              >
                Notifications
              </span>
            </Link>
          </li>
          <li className="flex justify-center md:justify-start">
            <Link
              to={`/profile/${authUser?.username}`}
              className="flex gap-3 items-center hover:bg-stone-900 transition-all rounded-full duration-300 py-2 pl-2 pr-4 max-w-fit cursor-pointer"
            >
              <FaUser
                className={`w-6 h-6 ${
                  location.pathname === `/profile/${authUser?.username}`
                    ? "text-pink-500"
                    : "text-white"
                }`}
              />
              <span
                className={`text-lg hidden lg:block ${
                  location.pathname === `/profile/${authUser?.username}`
                    ? "text-pink-500"
                    : "text-white"
                }`}
              >
                Profile
              </span>
            </Link>
          </li>
          <li className="flex justify-center md:justify-start">
            <Link
              to="/create-post"
              className="flex gap-3 items-center hover:bg-stone-900 transition-all rounded-full duration-300 py-2 pl-2 pr-4 max-w-fit cursor-pointer"
            >
              <HiOutlinePlusCircle
                className={`w-6 h-6 ${
                  location.pathname === "/create-post"
                    ? "text-pink-500"
                    : "text-white"
                }`}
              />
              <span
                className={`text-lg hidden lg:block ${
                  location.pathname === "/create-post"
                    ? "text-pink-500"
                    : "text-white"
                }`}
              >
                Create Post
              </span>
            </Link>
          </li>

          <li>
            <Link
              to="/search"
              className="flex gap-3 items-center hover:bg-stone-900 transition-all rounded-full duration-300 py-2 pl-2 pr-4 max-w-fit cursor-pointer"
            >
              <FiSearch
                className={`w-6 h-6 ${
                  location.pathname === "/search"
                    ? "text-pink-500"
                    : "text-white"
                }`}
              />
              <span
                className={`text-lg hidden lg:block ${
                  location.pathname === "/search"
                    ? "text-pink-500"
                    : "text-white"
                }`}
              >
                Explore
              </span>
            </Link>
          </li>
        </ul>
        <div className="flex-grow"></div>
        <div className="flex justify-center md:justify-start mb-4 px-2">
          <BiLogOut
            className="w-6 h-6 text-white cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              logout();
            }}
          />
          <span className="text-lg hidden lg:block ml-2 text-white">
            Logout
          </span>
        </div>
      </div>
    </div>
  );
};

export default SidebarMenu;
