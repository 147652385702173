// src/components/common/UserCard.jsx
import { Link } from "react-router-dom";

const UserCard = ({ user }) => {
  return (
    <Link
      to={`/profile/${user.username}`}
      className="flex items-center p-4 border-b border-gray-300"
    >
      <div className="avatar w-12 h-12 rounded-full overflow-hidden">
        <img
          src={user.profileImg || "/avatar-placeholder.png"}
          alt={user.username}
        />
      </div>
      <div className="ml-4">
        <p className="font-bold">{user.fullName}</p>
        <p className="text-sm text-gray-600">@{user.username}</p>
      </div>
    </Link>
  );
};

export default UserCard;
