import React, { useEffect, useState } from "react";
import { FaWhatsapp, FaFacebook, FaLink } from "react-icons/fa";
import { toast } from "react-hot-toast";

const ShareModal = ({ isOpen, onClose, postId, authUser }) => {
  const [isVisible, setIsVisible] = useState(false);

  // Generate the post URL dynamically using postId
  const postUrl = `https://256vyro.com/post/${postId}`;

  // Log share action to the backend and increment share count
  const logShareAction = (platform) => {
    fetch(`/api/posts/${postId}/shares`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId: authUser._id,
        platform,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          toast.success(`Shared on ${platform}`);
        } else {
          toast.error("Failed to log the share.");
        }
      })
      .catch((error) => {
        console.error("Error logging the share:", error);
        toast.error("Failed to log the share.");
      });
  };

  // Handle copy link
  const handleCopyLink = () => {
    navigator.clipboard
      .writeText(postUrl)
      .then(() => {
        toast.success("Link copied to clipboard!");
        logShareAction("Copy Link");
        onClose(); // Close the modal after copying
      })
      .catch((err) => console.error("Failed to copy: ", err));
  };

  // Handle share to WhatsApp
  const handleShareWhatsApp = () => {
    const message = encodeURIComponent("Check out this post!");
    window.open(
      `https://api.whatsapp.com/send?text=${message} ${postUrl}`,
      "_blank"
    );
    logShareAction("WhatsApp");
    onClose(); // Close the modal after sharing
  };

  // Handle share to Facebook
  const handleShareFacebook = () => {
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        postUrl
      )}`,
      "_blank"
    );
    logShareAction("Facebook");
    onClose(); // Close the modal after sharing
  };

  // Close the modal when clicking outside
  const handleOutsideClick = (e) => {
    if (e.target.classList.contains("modal-overlay")) {
      onClose();
    }
  };

  // Control visibility with animation
  useEffect(() => {
    if (isOpen) {
      setIsVisible(true);
    } else {
      setTimeout(() => setIsVisible(false), 300); // Delay for smooth slide out
    }
  }, [isOpen]);

  if (!isVisible) return null;

  return (
    <div
      className="modal-overlay fixed inset-0 z-50 bg-black bg-opacity-50 flex justify-center items-end"
      onClick={handleOutsideClick}
    >
      <div className="modal-container bg-white rounded-t-lg p-4 w-full max-w-md transition-transform transform translate-y-100">
        <h2 className="text-lg font-bold mb-4 text-center text-black">
          Share to
        </h2>
        <div className="grid grid-cols-3 gap-4 mb-4">
          <button
            onClick={handleCopyLink}
            className="flex flex-col items-center"
          >
            <FaLink className="text-blue-500 w-6 h-6" />
            <span className="text-black">Copy Link</span>
          </button>
          <button
            onClick={handleShareWhatsApp}
            className="flex flex-col items-center"
          >
            <FaWhatsapp className="text-green-500 w-6 h-6" />
            <span className="text-black">WhatsApp</span>
          </button>
          <button
            onClick={handleShareFacebook}
            className="flex flex-col items-center"
          >
            <FaFacebook className="text-blue-500 w-6 h-6" />
            <span className="text-black">Facebook</span>
          </button>
        </div>

        {/* Close Button */}
        <div
          className="text-blue-500 text-center cursor-pointer"
          onClick={onClose}
        >
          Close
        </div>
      </div>
    </div>
  );
};

export default ShareModal;
