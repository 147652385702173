import { Link, useLocation } from "react-router-dom";
import { MdHomeFilled } from "react-icons/md";
import { FiSearch } from "react-icons/fi";
import { HiOutlinePlusCircle } from "react-icons/hi";
import { MdOutlineOndemandVideo } from "react-icons/md";
import { useState } from "react";

const BottomBar = ({ authUser }) => {
  const location = useLocation();

  return (
    <div className="fixed bottom-0 left-0 right-0 z-40 bg-black border-t border-gray-700 md:hidden py-3">
      <ul className="flex justify-around items-center space-x-4">
        <li className="flex flex-col items-center">
          <Link to="/" className="flex flex-col items-center">
            <MdHomeFilled
              className={`w-7 h-7 ${
                location.pathname === "/" ? "text-pink-500" : "text-white"
              }`}
            />
            <span
              className={`text-xs mt-1 ${
                location.pathname === "/" ? "text-pink-500" : "text-white"
              }`}
            >
              Home
            </span>
          </Link>
        </li>

        <li className="flex flex-col items-center">
          <Link to="/vyros" className="flex flex-col items-center">
            <MdOutlineOndemandVideo
              className={`w-7 h-7 ${
                location.pathname === "/vyros" ? "text-pink-500" : "text-white"
              }`}
            />
            <span
              className={`text-xs mt-1 ${
                location.pathname === "/vyros" ? "text-pink-500" : "text-white"
              }`}
            >
              Vyros
            </span>
          </Link>
        </li>

        <li className="flex flex-col items-center">
          <Link to="/create-post" className="flex flex-col items-center">
            <HiOutlinePlusCircle className="w-12 h-12 text-white" />
          </Link>
        </li>

        <li className="flex flex-col items-center">
          <Link to="/search" className="flex flex-col items-center">
            <FiSearch
              className={`w-7 h-7 ${
                location.pathname === "/search" ? "text-pink-500" : "text-white"
              }`}
            />
            <span
              className={`text-xs mt-1 ${
                location.pathname === "/search" ? "text-pink-500" : "text-white"
              }`}
            >
              Search
            </span>
          </Link>
        </li>

        <li className="flex flex-col items-center">
          <Link
            to={`/profile/${authUser?.username}`}
            className="flex flex-col items-center"
          >
            <div
              className={`w-7 h-7 rounded-full overflow-hidden border ${
                location.pathname === `/profile/${authUser?.username}`
                  ? "border-pink-500"
                  : "border-gray-700"
              }`}
            >
              <img
                src={authUser?.profileImg || "/avatar-placeholder.png"}
                alt="Profile"
                className="w-full h-full object-cover"
              />
            </div>
            <span
              className={`text-xs mt-1 ${
                location.pathname === `/profile/${authUser?.username}`
                  ? "text-pink-500"
                  : "text-white"
              }`}
            >
              Profile
            </span>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default BottomBar;
