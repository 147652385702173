import Post from "./Post";
import PostSkeleton from "../skeletons/PostSkeleton";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";

const Posts = ({ feedType, username, userId }) => {
  const getPostEndpoint = (page = 1) => {
    switch (feedType) {
      case "forYou":
        return `/api/posts/all?page=${page}&limit=10`;
      case "following":
        return `/api/posts/following?page=${page}&limit=10`;
      case "posts":
        return `/api/posts/user/${username}?page=${page}&limit=10`;
      case "likes":
        return `/api/posts/likes/${userId}?page=${page}&limit=10`;
      default:
        return `/api/posts/all?page=${page}&limit=10`;
    }
  };

  const fetchPosts = async ({ pageParam = 1 }) => {
    const res = await fetch(getPostEndpoint(pageParam));
    const data = await res.json();

    if (!res.ok) {
      throw new Error(data.error || "Something went wrong");
    }

    return {
      posts: data.posts,
      nextPage: data.hasNextPage ? pageParam + 1 : undefined,
    };
  };

  const {
    data,
    isLoading,
    isError,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: ["posts", feedType, username, userId],
    queryFn: fetchPosts,
    getNextPageParam: (lastPage) => lastPage.nextPage,
  });

  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, fetchNextPage]);

  if (isLoading) {
    return (
      <div className="flex flex-col justify-center">
        <PostSkeleton />
        <PostSkeleton />
        <PostSkeleton />
      </div>
    );
  }

  if (isError) {
    return <p className="text-center my-4">Error loading posts</p>;
  }

  return (
    <>
      {data?.pages?.flatMap((page) =>
        page.posts.map((post) => (
          <Post
            key={post._id}
            post={post}
            feedType={feedType}
            username={username}
            userId={userId}
          />
        ))
      )}
      <div ref={ref} className="loading-more">
        {isFetchingNextPage && (
          <div className="flex flex-col justify-center">
            <PostSkeleton />
            <PostSkeleton />
            <PostSkeleton />
          </div>
        )}
      </div>
    </>
  );
};

export default Posts;
