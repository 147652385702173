// src/components/common/TermsOfServicePage.jsx
import React, { useEffect } from "react";

const TermsOfServicePage = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://app.termly.io/embed-policy.min.js";
    script.id = "termly-jssdk";
    script.async = true;

    const termlyDiv = document.createElement("div");
    termlyDiv.setAttribute("name", "termly-embed");
    termlyDiv.setAttribute("data-id", "55c6ad88-b95e-4010-881f-9d28e8465443");

    const termsContainer = document.getElementById("terms-container");
    if (termsContainer) {
      termsContainer.appendChild(termlyDiv);
      termsContainer.appendChild(script);
    }

    return () => {
      // Safely remove the elements only if they exist
      const termsContainer = document.getElementById("terms-container");
      if (termsContainer) {
        if (termlyDiv) termsContainer.removeChild(termlyDiv);
        if (script) termsContainer.removeChild(script);
      }
    };
  }, []);

  return (
    <div className="max-w-3xl mx-auto p-4 text-white">
      <div id="terms-container"></div>
    </div>
  );
};

export default TermsOfServicePage;
