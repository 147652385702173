import { useState, useRef, useEffect } from "react";
import Posts from "../../components/common/Posts";
import TopBar from "../../components/common/TopBar";

const HomePage = () => {
  const scrollRef = useRef(null);
  const [feedType, setFeedType] = useState(
    sessionStorage.getItem("feedType") || "forYou"
  );
  const storageKey = "scroll-position-home";

  // Restore scroll position on mount
  useEffect(() => {
    const savedPosition = parseInt(sessionStorage.getItem(storageKey), 10) || 0;

    // Use requestAnimationFrame to ensure DOM is ready
    const restoreScroll = () => {
      if (scrollRef.current) {
        scrollRef.current.scrollTop = savedPosition;
      }
    };
    requestAnimationFrame(restoreScroll);
  }, []);

  // Save scroll position on every scroll event
  const handleScroll = () => {
    if (scrollRef.current) {
      sessionStorage.setItem(storageKey, scrollRef.current.scrollTop);
    }
  };

  // Save feed type in sessionStorage whenever it changes
  useEffect(() => {
    sessionStorage.setItem("feedType", feedType);
  }, [feedType]);

  return (
    <div className="flex-[4_4_0] mr-auto border-r md:pt-0 pt-14 pb-20 border-gray-700 min-h-screen">
      <TopBar />
      <div
        ref={scrollRef}
        onScroll={handleScroll}
        className="overflow-auto h-full"
      >
        <Posts feedType={feedType} />
      </div>
    </div>
  );
};

export default HomePage;
