import React, { memo, useEffect, useRef } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Sidebar from "./Sidebar";
import RightPanel from "./RightPanel";

const PersistentLayout = memo(({ authUser }) => {
  const mainRef = useRef(null);
  const location = useLocation();

  // Key for storing the scroll position in sessionStorage
  const storageKey = `scroll-position-${location.pathname}`;

  // Restore scroll position when the component mounts or location changes
  useEffect(() => {
    const savedPosition = parseInt(sessionStorage.getItem(storageKey), 10) || 0;
    if (mainRef.current) {
      mainRef.current.scrollTop = savedPosition;
    }
  }, [location.pathname]); // Run this every time the pathname changes

  // Save scroll position in sessionStorage on every scroll
  const handleScroll = () => {
    if (mainRef.current) {
      sessionStorage.setItem(storageKey, mainRef.current.scrollTop);
    }
  };

  return (
    <div className="flex h-screen max-w-6xl mx-auto">
      {authUser && <Sidebar />}
      <div
        className="flex-1 overflow-auto"
        onScroll={handleScroll}
        ref={mainRef}
      >
        <Outlet />
      </div>
      {authUser && <RightPanel />}
    </div>
  );
});

export default PersistentLayout;
