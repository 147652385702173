import { useState } from "react";
import { Link } from "react-router-dom";
import {
  MdOutlineMail,
  MdPassword,
  MdDriveFileRenameOutline,
} from "react-icons/md";
import { FaUser } from "react-icons/fa";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import VyroVyrosSvg from "../../../components/svgs/VyroVyrosSvg";
import { FcGoogle } from "react-icons/fc"; // Import Google Icon

const checkPassword = (password) => {
  const minLength = 8;
  const uppercasePattern = /[A-Z]/;
  const lowercasePattern = /[a-z]/;
  const numberPattern = /[0-9]/;
  const specialCharacterPattern = /[!@#$%^&*(),.?":{}|<>]/;

  if (password.length < minLength)
    return `Password must be at least ${minLength} characters long`;
  if (!uppercasePattern.test(password))
    return "Password must contain at least one uppercase letter";
  if (!lowercasePattern.test(password))
    return "Password must contain at least one lowercase letter";
  if (!numberPattern.test(password))
    return "Password must contain at least one number";
  if (!specialCharacterPattern.test(password))
    return "Password must contain at least one special character";
  return null;
};

const calculatePasswordStrength = (password) => {
  let strength = 0;
  const criteria = {
    length: password.length >= 8,
    uppercase: /[A-Z]/.test(password),
    lowercase: /[a-z]/.test(password),
    number: /[0-9]/.test(password),
    specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
  };

  Object.values(criteria).forEach((isValid) => {
    if (isValid) strength++;
  });

  return { strength, criteria };
};

const SignUpPage = () => {
  const [formData, setFormData] = useState({
    email: "",
    username: "",
    fullName: "",
    password: "",
  });

  const [passwordError, setPasswordError] = useState(null);
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [passwordCriteria, setPasswordCriteria] = useState({});
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);
  const queryClient = useQueryClient();

  const { mutate, isError, isPending, error } = useMutation({
    mutationFn: async ({ email, username, fullName, password }) => {
      const res = await fetch("/api/auth/signup", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include", // Include cookies (JWT)
        body: JSON.stringify({ email, username, fullName, password }),
      });

      const data = await res.json();
      if (!res.ok) throw new Error(data.error || "Failed to create account");
      return data;
    },
    onSuccess: () => {
      toast.success("Account created successfully");
      queryClient.invalidateQueries({ queryKey: ["authUser"] });
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    const passwordValidationError = checkPassword(formData.password);
    if (passwordValidationError) {
      setPasswordError(passwordValidationError);
      return;
    }
    mutate(formData);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === "password") {
      const { strength, criteria } = calculatePasswordStrength(value);
      setPasswordStrength(strength);
      setPasswordCriteria(criteria);
    }
  };

  const renderPasswordStrength = () => {
    const strengthLabels = ["Weak", "Fair", "Good", "Strong", "Very Strong"];
    const strengthColors = ["red", "orange", "yellow", "lightgreen", "green"];

    return (
      <div className="mt-2">
        <div
          className={`w-full h-2 rounded-full`}
          style={{
            backgroundColor: strengthColors[passwordStrength - 1],
            width: `${(passwordStrength / 5) * 100}%`,
          }}
        ></div>
        <p className={`text-${strengthColors[passwordStrength - 1]}`}>
          {strengthLabels[passwordStrength - 1]}
        </p>
      </div>
    );
  };

  const renderPasswordCriteria = () => (
    <ul className="mt-2 text-sm text-gray-500">
      <li
        className={passwordCriteria.length ? "text-green-500" : "text-red-500"}
      >
        Password must be at least 8 characters long
      </li>
      <li
        className={
          passwordCriteria.uppercase ? "text-green-500" : "text-red-500"
        }
      >
        At least one uppercase letter
      </li>
      <li
        className={
          passwordCriteria.lowercase ? "text-green-500" : "text-red-500"
        }
      >
        At least one lowercase letter
      </li>
      <li
        className={passwordCriteria.number ? "text-green-500" : "text-red-500"}
      >
        At least one number
      </li>
      <li
        className={
          passwordCriteria.specialChar ? "text-green-500" : "text-red-500"
        }
      >
        At least one special character
      </li>
    </ul>
  );

  const handleGoogleLogin = () => {
    window.location.href = "/api/auth/google"; // Redirect to Google OAuth endpoint
  };

  return (
    <div className="max-w-screen-xl mx-auto flex h-screen px-10">
      <div className="flex-1 flex flex-col justify-center items-center">
        <form
          className="lg:w-2/3 mx-auto md:mx-20 flex gap-4 flex-col"
          onSubmit={handleSubmit}
        >
          <div className="flex flex-col items-center justify-center ">
            <VyroVyrosSvg className="w-24 items-center lg:hidden fill-white" />
          </div>
          <h1 className="text-3xl font-bold text-white">
            Sign up for 256 Vyro
          </h1>
          {/* Google Login Button */}
          <button
            onClick={handleGoogleLogin}
            className="btn rounded-full btn-outline text-white flex items-center gap-2 mt-4"
          >
            <FcGoogle size={20} />
            <span>Continue with Google</span>
          </button>
          <div className="flex items-center">
            <div className="flex-grow border-t border-pink-500"></div>
            <span className="mx-4 text-pink-500">or</span>
            <div className="flex-grow border-t border-pink-500"></div>
          </div>
          <label className="input input-bordered rounded flex items-center gap-2">
            <MdOutlineMail />
            <input
              type="email"
              className="grow"
              placeholder="Email"
              name="email"
              onChange={handleInputChange}
              value={formData.email}
            />
          </label>
          <div className="flex gap-4 flex-wrap">
            <label className="input input-bordered rounded flex items-center gap-2 flex-1">
              <FaUser />
              <input
                type="text"
                className="grow"
                placeholder="Username"
                name="username"
                onChange={handleInputChange}
                value={formData.username}
              />
            </label>
            <label className="input input-bordered rounded flex items-center gap-2 flex-1">
              <MdDriveFileRenameOutline />
              <input
                type="text"
                className="grow"
                placeholder="Full Name"
                name="fullName"
                onChange={handleInputChange}
                value={formData.fullName}
              />
            </label>
          </div>
          <label className="input input-bordered rounded flex items-center gap-2">
            <MdPassword />
            <input
              type="password"
              className="grow"
              placeholder="Password"
              name="password"
              onChange={handleInputChange}
              onFocus={() => setIsPasswordFocused(true)}
              onBlur={() => setIsPasswordFocused(formData.password !== "")}
              value={formData.password}
            />
          </label>
          {isPasswordFocused &&
            formData.password !== "" &&
            renderPasswordStrength()}
          {isPasswordFocused &&
            formData.password !== "" &&
            renderPasswordCriteria()}
          {passwordError && <p className="text-red-500">{passwordError}</p>}
          <button className="btn rounded-full btn-primary text-white">
            {isPending ? "Loading..." : "Create account"}
          </button>
          {isError && <p className="text-red-500">{error.message}</p>}
        </form>
        <div className="flex flex-col lg:w-2/3 gap-2 mt-4">
          <Link to="/login">
            <button className="btn rounded-full btn-primary text-white btn-outline w-full">
              Already have an account?
            </button>
          </Link>
        </div>

        {/* Privacy Policy */}
        <div className="mt-4 p-4 text-gray-400 max-w-md mx-auto">
          <p className="text-sm p-4 leading-normal text-center">
            By continuing with an account, you agree to our{" "}
            <Link to="/terms-of-service" className="underline text-white">
              Terms of Service
            </Link>{" "}
            and acknowledge that you have read our{" "}
            <Link to="/privacy-policy" className="underline text-white">
              Privacy Policy
            </Link>
            .
          </p>
        </div>
      </div>
    </div>
  );
};

export default SignUpPage;
